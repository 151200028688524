export const flattenString = (string) => {
    // Removes leading and trailing wite spaces...
    let stringCleaned = string.replace(/^\s+|\s+$/g, '');
    // Replaces spaces between words wit an underscore...
    stringCleaned = stringCleaned.replace(/\s/g, '_');
    // Returns te result in lowercase.
    return stringCleaned.toLowerCase();
};

export const titleCase = (str) => {
    if(!str || str === 'n/a' || str === 'N/A' || str === '' || typeof str !== 'string') return 'N/A';
    let splitStr = str.toLowerCase().replaceAll('_', ' ').split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    };
    return splitStr.join(' '); 
};

export const formatPhone = (phoneRaw) => {
    let phoneValue = phoneRaw.replace(/[^\d]/g, '');
    return phoneValue.substr(-10);
};

// Cookie Tools
export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const checkCookie = (cname) => {
    let cookie = getCookie(cname);
    if (cookie && cookie !== '') {
        return true
    } 
    return false;
};

export const setCookies = (tracking) => {
    setCookie('oid', tracking.oid, 3);
    setCookie('pid', tracking.pid, 3);
    setCookie('sid', tracking.sid, 3);
    setCookie('uid', tracking.uid, 3);
    setCookie('eid', tracking.eid, 3);
    setCookie('hsid', tracking.hsid, 3);
    setCookie('se', tracking.se, 3);
    setCookie('kwd', tracking.kwd, 3);
    setCookie('pacid', tracking.pacid, 3);
    setCookie('pt1', tracking.pt1, 3);
    setCookie('pt2', tracking.pt2, 3);
	setCookie('gclid', tracking.gclid, 3);
};

// Build the full like for an offer
export const buildFullLink = (link, sid, eid, hsid) => {
    let linkout = link // eslint-disable-next-line
    linkout = linkout.replace('${sid}', sid); // eslint-disable-next-line
    linkout = linkout.replace('${eid}', eid); // eslint-disable-next-line
    linkout = linkout.replace('${hsid}', hsid);
    return linkout;
};

const returnApprovalAmount = (amount) => {
    if(amount === 0) return amount.toString();
    if(amount > 10000) return (amount/100).toString();
    if(typeof amount === 'string') return amount;
    return amount.toString();
};

export const returnApprovalStatus = (status) => {
    switch(status) {
        case 'APPROVED':
            return 'approved';
        case 'Approved':
            return 'approved';
        case 'Denied':
            return 'not_approved';
        case 'DENIED':
            return 'not_approved';
        case 'approved':
            return 'approved';
        case 'preApproved':
            return 'approved';
        case 'pt-pre-approval':
            return 'approved';
        case 'pt-decline':
            return 'not_approved';
        case 'pt-error':
            return 'not_approved';
        case 'not_approved':
            return 'not_approved';
        case 'denied':
            return 'not_approved';
        case 'abandoned':
            return 'abandoned';
        case 'closed modal with X button':
            return 'abandoned';
        case 'Cancelled':
            return 'abandoned';
        case 'QA_VERIFICATION':
            return 'abandoned';
        case 'MANUAL_VERIFICATION':
            return 'abandoned';
        case 'NEW_CUSTOMER':
            return 'abandoned';
        case 'NO_DECISION':
            return 'abandoned';
        case 'ineligible':
            return 'not_approved';
        case 'HardDecline':
            return 'not_approved';
        case 'hardDecline':
            return 'not_approved';
        case 'Exception':
            return 'exception';
        case 'NoLender':
            return 'not_approved';
        case 'Delivered':
            return 'delivered';
        default:
            return `Unknown response: ${status}`
    }
}
export const buildDoradoUser = (user, tracking) => ({
    email: user['email'],
    first: user['first'],
    last: user['last'],
    city: tracking['city'],
    state: tracking['state'],
    zip: tracking['zip'].toString(),
    phone: user['phone'].toString(),
    country: tracking['country'],
    pid: tracking['pid'].toString(),
    oid: tracking['oid'].toString(),
    sid: tracking['sid'].toString(),
    eid: tracking['eid'],
    uid: tracking['uid'],
    application_status: returnApprovalStatus(user.application_status),
    application_id: user?.application_id ?? '',
    application_token: user?.application_token ?? '',
    sub_provider: user?.sub_provider ?? '',
    wc_order_id: user?.wc_order_id ?? '',
    aff_app_id: user?.aff_app_id ?? '',
    flex_app_id: user?.flex_app_id ?? '',
    terrace_app_id: user?.terrace_app_id ?? '',
    bot_app_id: user?.bot_app_id ?? '',
    approval_amount: returnApprovalAmount(user.approval_amount),
    approval_date: new Date().toISOString(),
    clickId: user['clickId'].toString(),
    gclid: tracking['gclid']
});
export const buildFlexDoradoUser = (user, tracking, address) => ({
    email: user['email'],
    first: user['first'],
    last: user['last'],
    city: address['city'],
    state: address['state'],
    zip: address['zip'].toString(),
    phone: user['phone'].toString(),
    country: address['country'],
    pid: tracking['pid'].toString(),
    oid: tracking['oid'].toString(),
    sid: tracking['sid'].toString(),
    eid: tracking['eid'],
    uid: tracking['uid'],
    application_status: returnApprovalStatus(user.application_status),
    application_id: user?.application_id ?? '',
    wc_order_id: user?.wc_order_id ?? '',
    aff_app_id: user?.aff_app_id ?? '',
    flex_app_id: user?.flex_app_id ?? '',
    approval_amount: returnApprovalAmount(user.approval_amount),
    approval_date: new Date().toISOString(),
    clickId: user['clickId'].toString(),
    gclid: tracking['gclid']
});

export const isFirstStart = (provider) => {
    const startValue = getCookie('startWith');
    if(!startValue || startValue !== provider) {
        console.log('No start cookie for:', provider);
        setCookie('startWith', provider, 1);
        return true;
    };
    console.log('Start already added for user.');
    return false;
};