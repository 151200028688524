import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context';
import Radium from 'radium';
import Qualified from './Qualified';
import Declined from './Declined';

const Results = Radium(() => {
    const [ prequalAmount, setAmount ] = useState(null);
    const { appState } = useContext(AppContext);
    const { qualify } = appState;

    const useFormula = (amount, percent) => {
        return Math.round(amount * (percent/100));
    };

    useEffect(() => {
        const percent = qualify.percent;
        let result = useFormula(qualify.income, percent);
        if(percent === 0) {
            setAmount(0);
            return;
        };
        if(percent === 45) {
            setAmount(result >= 750 ? 750 : result);
            return;
        };
        if(percent === 55) {
            setAmount(result >= 1500 ? 1500 : result);
            return;
        };
        if(percent === 65) {
            setAmount(result >= 2500 ? 2500 : result);
            return;
        };
    },[qualify]);

    if(prequalAmount === null) return;

    return (prequalAmount > 0 ? <Qualified amount={prequalAmount}/> : <Declined />);
});

export default Results;