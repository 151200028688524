import React, { useState, useEffect, useContext, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context';
import { firePreapprovalPixel, firePixelFB, fireNewPixelFB, fireBingTag, fireAdWordGtag } from '../../../utilities/pixels';
import { ADD_SUBSCRIBER } from '../../../utilities/mutations';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lotties/confetti-cannons.json';
import HeadShake from 'react-reveal/HeadShake';
import { useMutation } from '@apollo/client';
import useMediaQuery from 'react-responsive';
import styles from './Result.css.js';

const Congratulations = () => {
    // let history = useHistory();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const hasFired = useRef(false);
    const [ windowWidth ] = useState(window.innerWidth);
    const [ windowHeight ] = useState(window.innerHeight);
    const [ isComplete, setConfettiComplete ] = useState(false);
    const [ dollarAmount, setAmount ] = useState(null);
    const { appState, GtagValueEvent, trackingState } = useContext(AppContext);
    const { hsid, sid } = trackingState;
    const { user } = appState;
    
    const [ addNewSubscriber ] = useMutation(ADD_SUBSCRIBER, {
        onCompleted: (data) => {console.log(data.addNewSubscriber.message)},
        onError: (err) => {console.error(err)}
    });

    // if(!user.application_status) {
    //     history.push('/');
    // };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const handleClick = () => {
        window.location.href = 'https://buyontrust.com/';
    };

    const updateConfettiState = () => {
        setConfettiComplete(true);
    };

    const defaultOptions = {
		loop: false,
		autoplay: true, 
		animationData: animationData,
		rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
		}
    };
    
    useEffect(() => {
        const amountInt = Number(user.approval_amount);
        const dollars = (amountInt >= 10000) ? (amountInt / 100) : amountInt;
        setAmount(dollars);
		// eslint-disable-next-line
    }, []);

    const checkAmount = () => {
        let numberAmount = Number(dollarAmount);
        if(numberAmount > 500) {
            firePreapprovalPixel(hsid, sid, dollarAmount);
            fireAdWordGtag(dollarAmount);
            GtagValueEvent('Approvals', 'Application Result', 'Acima Approval', dollarAmount, 'Open Approval Page');
            fireBingTag('approval', dollarAmount);
            firePixelFB();
            fireNewPixelFB();
        };
    };

    useEffect(() => {
		if(!!user.application_status && !hasFired.current && !!dollarAmount) {
            checkAmount();
            addNewSubscriber({
                variables: { 
                    subscriber: {
                        clickId: Number(hsid),
                        first: user.first,
                        last: user.last,
                        phone: user.phone,
                        approval_amount: dollarAmount
                    } 
                }
            });
            hasFired.current = true;
        };

		// eslint-disable-next-line
    }, [user, hasFired.current, dollarAmount]);
    
    const rememberTextStyle = Object.assign({}, 
        styles.rememberText,
        isMobile && styles.mobileRememberText
    );
    const specialNoteStyle = Object.assign({}, 
        styles.specialNoteText,
        isMobile && styles.mobileSpecialNoteText
    );
    const nextStepsHeaderStyle = Object.assign({}, 
        styles.nextStepsHeader,
        isMobile && styles.mobileNextStepsHeader
    );
    const nextStepItemStyle = Object.assign({}, 
        styles.nextStepItem,
        isMobile && styles.mobileNextStepItem
    );

    const NextStepItem = ({text, idx}) => (<li key={`next-step__item-${idx}`} style={nextStepItemStyle}>{text}</li>);

    return (
        isComplete ? (
            <div style={styles.congratsContainer}>
                <div style={styles.congratsText}>Congratulations!</div>
                {
                user.approval_amount ? (
                    <div style={styles.subTextContainer}>
                        <div style={styles.congratsSubText}>
                            You've been approved for:
                        </div>
                        <div className='approval-amount__countainer'>
                            {`$${dollarAmount}`}
                        </div>
                    </div>
                ) : (
                    <div style={styles.subTextContainer}>
                        <div style={styles.congratsSubText}>
                            You've been approved!
                        </div>
                        <div style={styles.congratsSubText}>
                            You will receive your approval amount shortly in your email.
                        </div>
                    </div>
                )
                }
                <div style={specialNoteStyle}>
                    <span>
                    **PLEASE NOTE**<br />
                    Make sure you order all desired items at once!<br />
                    You can only have one lease at a time.
                    </span>
                </div>
                <div style={styles.nextStepsContainer}>
                    <div style={nextStepsHeaderStyle}>Next Steps:</div>
                    <ol type='1' style={styles.nextStepsList}>
                        <NextStepItem text='Shop Our Huge Inventory of Brand Name Electronics.' idx={0}/>
                        <NextStepItem text='Pick a local BestBuy location at checkout.' idx={1}/>
                        <NextStepItem text='Complete Underwriting Process and Sign Your Lease.' idx={2}/>
                        <NextStepItem text='Make a small initial payment with your credit/debit card.' idx={3}/>
                        <NextStepItem text='Pickup your order at your local Best Buy Store.' idx={4}/>
                    </ol>
                </div>
                <div style={rememberTextStyle}>
                    Remember, $0 is due when you pick up your merchandise!!
                </div>
                <HeadShake forever timeout={2000}>
                    <button className='shop-now__button' onClick={handleClick}>Shop Now!</button>
                </HeadShake>
            </div>
        ) : (
            <div style={{minHeight: '100vh'}}>
                <Lottie 
                    className='lottie-loader'
                    options={defaultOptions}
                    height={windowHeight}
                    width={windowWidth}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: updateConfettiState,
                        },
                    ]}
                />
            </div>
        )
    )
};

export default Congratulations;