const routingErr = { 
    required: '*Required',
    minLength: {
        value: 9,
        message: 'Min length is 9'
    },
    maxLength: {
        value: 9,
        message: 'Max length is 9'
    }
};

export default {
    routingErr
}