import React, { useEffect, useContext, useRef, useState } from 'react';
import { AppContext } from '../../../context';
import styles from './ApprovalPayTomorrow.css.js';
import { useHistory } from 'react-router-dom';
import { ADD_USER_APPROVAL, POST_DORADO } from '../../../utilities/mutations';
import { buildDoradoUser,returnApprovalStatus } from '../../../utilities/helpers';
import { useMutation } from '@apollo/client';
import axios from 'axios';

const ApprovalPayTomorrow = () => {
    let history = useHistory();
    const sentToMongo = useRef(false);
	const sentToDorado = useRef(false);
    const [ iframeUrl, setIframeUrl ] = useState(null);
    const [ appToken, setAppToken ] = useState(null);
	const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

    const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

    const handleApplicationFinish = async(dataObj) => {
        if(!sentToMongo.current) {
            addUserApproval({ 
                variables: { 
                    clickId: Number(trackingState.hsid),
                    phone: user?.phone,
                    email: user?.email,
                    approvalData: {
                        application_token: String(appToken),
                        application_status: dataObj.application_status,
                        approval_amount: Number(dataObj.approval_amount),
                        sub_provider: dataObj.sub_provider
                    }
                }
            });
            sentToMongo.current = true;
        };
        const userData = Object.assign({},
            user, {
            application_token: appToken,
            application_status: dataObj.application_status,
            approval_amount: String(dataObj.approval_amount),
            sub_provider: dataObj.sub_provider
        });
        dispatchApp({type:'USER_APPROVAL', payload: userData});
		if(!sentToDorado.current) {
            const doradoBody = buildDoradoUser(userData, trackingState);
            console.log('dorado body post:', doradoBody);
            postUserDorado({ variables: { user: doradoBody }});
            sentToDorado.current = true;
            if(dataObj.application_status === 'approved') {
                history.push('/Congratulations');
            } else {
                history.push('/Declination');
            };
        };
    };

    window.onmessage = (event) => {
        if(event.origin === 'https://consumer.paytomorrow.com' && event.data?.type === 'TikTokPixelSPAMonitor') {
            return;
        };
        if(event.origin === 'https://consumer.paytomorrow.com' && !!event.data?.message) {
            console.log('Message from paytomorrow!', event.data?.message);
            const decision = returnApprovalStatus(event.data.message);
            const dataObj = {
                application_token: appToken,
                approval_amount: decision === 'approved' ? event.data.approvalAmount : 0,
                application_status: decision,
                sub_provider: event.data.lender
            };
            console.log('approval data:', dataObj);
            handleApplicationFinish(dataObj);
            return;
        };
        if(event.origin === 'https://consumer.paytomorrow.com' && !!event.data) {
            if(event.data !== 'pt-close' && event.data !== 'pt-decline' && event.data !== 'pt-error') {
                if(event.data === 'inspiha') return;
                console.log('Data does not fit pattern:', event.data);
                return;
            } else {
                console.log('Data from paytomorrow!', event.data);
                const dataObj = {
                    application_token: appToken,
                    approval_amount: 0,
                    application_status: returnApprovalStatus(event.data),
                    sub_provider: null
                };
                console.log('decline data:', dataObj);
                handleApplicationFinish(dataObj);
                return;
            };
        };
	};

    const getIframeUrl = async() => {
        // let preapprovalLink = 'https://api-staging.paytomorrow.com/api/ecommerce/public/organizations/pre-approval/0128c75b90dbc7001939a05ed64b00bb';
        let preapprovalLink = 'https://api.paytomorrow.com/api/ecommerce/public/organizations/pre-approval/96436f7dd8a1a9a54fbcc7745f59f6cb';
        try{
            const res = await axios({
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=utf-8'
                },
                url: preapprovalLink,
                data: {
                    firstName: user.first,
                    lastName: user.last,
                    email: user.email,
                    cellPhone: user.phone
                }
            });
            if(res.status !== 200) {
                throw new Error('Error getting PayTomorrow iFrame url.');
            };
            const url = res.data.url;
            const token = res.data.token;
            setIframeUrl(url);
            setAppToken(token);
            return;
        } catch(err) {
            return {status: 'failed', message: err};
        }
    };

    useEffect(() => {
        if(!iframeUrl) {
            getIframeUrl();
        };
        // eslint-disable-next-line
    },[iframeUrl]);

    return(
        <div style={{ minHeight: '500px' }}>
            {!iframeUrl ?
                "...Loading" :
                <iframe style={styles.iframeStyle} title='paytomorrow' id='application-pay' src={iframeUrl}></iframe>
            }
        </div>
    )
};

export default ApprovalPayTomorrow;