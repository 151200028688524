const styles = {
    offerButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '30px',
        paddingRight: '10px'
    },
    offerButtonHeader:{
        fontSize: '1.4rem',
        // borderBottom: '2px solid rgb(0,179,236)',
        color: 'rgb(0,179,236)',
    },
    offerSubHeader: {
        marginTop: '10px',
        textAlign: 'left',
        fontSize: '1rem'
    },
    offerButtonRowSm: {
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: '10px'
    },
    offerButtonRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '10px'
    },
    offerButton: {
        width: '100%',
        height: '50px',
        background: 'linear-gradient(0deg, rgba(255,153,58,1) 0%, rgba(255,205,0,1) 50%)',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        border: '2px solid rgb(77,107,137)'
    },
    offerButtonSm: {
        width: '60%',
        fontSize: '1rem',
    },
    offerButtonSponsored: {
        fontSize: '1rem',
        opacity: '0.5',
        marginLeft: '10px'
    },
    hover: {
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.4rem',
    },
    pageContainer: {
        maxWidth: '800px'
    },
    mobilePageContainer: {
        width: '100vw',
        padding: '20px'
    },
    declineHeader: {
        fontWeight: 'bold',
        fontSize: '2rem',
        marginBottom: '20px'
    },
    declineText: {
        fontWeight: '300',
        fontSize: '1.2rem',
        marginBottom: '20px'
    },
    declineContainer: {
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '10vh'
    },
    congratsSubText: {
        fontSize: '1.4rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0 10px'
    },
    subTextContainer: {
        marginBottom: '10px'
    },
    rememberText: {
        fontSize: '1.4rem',
        marginBottom: '40px',
        fontWeight: '500',
        padding: '0 10px',
        textAlign: 'center'
    },
    mobileRememberText: {
        fontSize: '1.2rem',
		textAlign: 'center'
    },
    congratsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh'
    },
    congratsText: {
        fontSize: '2.4rem',
        padding: '10px 0 0 0',
        marginTop: '2vh',
        fontWeight: '500',
    },
    specialNoteText: {
        fontSize: '1rem',
        marginBottom: '40px',
        textAlign: 'center',
    },
    mobileSpecialNoteText: {
        fontSize: '1.1rem',
        padding: '0 10px',
    },
    nextStepsContainer: {
        borderTop: 'solid 1px rgb(190, 190, 190)',
        borderBottom: 'solid 1px rgb(190, 190, 190)',
        marginBottom: '20px',
        padding: '30px 10px',
    },
    nextStepsHeader: {
        textAlign: 'left',
        marginLeft: '40px',
        fontSize: '1.5rem',
        fontWeight: '500',
    },
    mobileNextStepsHeader: {
        marginLeft: '10px',
    },
    nextStepsList: {
        textAlign: 'left',
    },
    nextStepItem: {
        fontWeight: '300',
        fontSize: '1.1rem',
    },
    mobileNextStepItem: {
        marginBottom: '10px',
    }
};

export default styles;