import React, { useState } from 'react';
import styles from './ApprovalCustom.css.js';
import Radium from 'radium';
import { useMediaQuery } from 'react-responsive';

const CustomButton = Radium(({text, buttonClick, value}) => {
    const [ isHovering, setHovering ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const themedStyle = Object.assign({},
        styles.customButton,
        isHovering && styles.hover
    );

    const sendingData = { text, value };

    return (
        <button
            onMouseEnter={() => setHovering(!isMobile)}
            onMouseLeave={() => setHovering(false)}
            style={themedStyle}
            type='submit'
            onClick={() => buttonClick(sendingData)}
        >
            {text}
        </button>
    )
});

export default CustomButton;