import React from "react";

function SvgPhone(props) {
  return (
    <svg width={18} height={22.5} {...props}>
      <image
        width={18}
        height={22.5}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAYAAAA2Lt7lAAABv0lEQVRIia3Wy6tOURgG8N/hhITklDoDTORSJsqZyIBDLkkGMpeBUib+BRkYnwmiKJmgDEhRpBBJnTq5naRccysiMnFr1dratrX3d1n7mXzfet73e55vvftd+10DS8881wIWYjOe4UpZbrAF8Y24gGlxfR378CAspmSKz8C5knjAWtzEtjYMjmB2gp+LkxjKMZiJHQ3xeRjNMRjFrA45IzkGi/G7Q87UHIPLeNMhZyLH4DG2435N/Bee5hiE8tzDGjxKxAcwmdumAZ9xO8FP4kMbBkFjWYI/poWDFrAAqxP86bYMjie4i3jXhkF472xI8IeKLzkG4RSfT/A3cKsNg7H4Uqtib3ndr8EW7ErwR4s5UKCfgTOESwn+LfZXyV53MB0Pa2I78b2TwTBO4T3OYmUpNh/j8bOKsTjF/kN56C+KR364lPQJB/ARB+OhquIa1tdtuTCYg1c1468JT+Iuv9XlFCW62of4a6xrEhe7aASrehR/iRX40ilxMHZGLwhlWY6f3fwmlOhOw1Sq4gSWdCteGPzAVtxtyPuKTdjd427/PuQX0eRwpa6hs/bEBvjnztktUpff8PDCPw4X2TzgDyOXUAUUplPLAAAAAElFTkSuQmCC"
      />
    </svg>
  );
}

export default SvgPhone;
