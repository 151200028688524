import React, { useEffect, useContext, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context';
import styles from './Result.css.js';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

const DeclineAff = () => {
    const hasFired = useRef(false);
    const { GtagValueEvent } = useContext(AppContext);

    useEffect(() => {
		if(!hasFired.current) {
            GtagValueEvent('Approvals', 'Application Result', 'Aff Denial', 0, 'Open Denial Page');
            hasFired.current = true;
        };

		// eslint-disable-next-line
    }, [hasFired]);

    return (
        <div className='page-container' style={styles.pageContainer}>
            <div style={styles.declineContainer}>
                <div style={styles.declineHeader}>
                    Unfortunately we're unable to approve your application.
                </div>
                <div style={styles.declineText}>
                    You will be sent an adverse action notice within 30 days to the address from the application with more details on the decision.
                </div>
                <div style={styles.declineText}>
                    If you have any questions, you can call us at (877)264-2143.
                </div>
            </div>
        </div>
    );
}

export default DeclineAff;