import gql from 'graphql-tag.macro';

const OFFER = gql`
	query GetOfferByPid($pid: Int, $route: String) {
		getOfferByPid(pid: $pid, route: $route) {
			success
			statusCode
			message
			data {
				id
				title
				layout {
					title
					didYouKnow
					text
				}
				link
				jumpBehind
				autoPop
				usage
			}
		}
	}
`;

const FETCH_PROVIDERS = gql`
    query{
        fetchProviderSelection {
            success
            statusCode
            message
            data {
                acima
                westcreek
				aff
				flex
				paytomorrow
				terrace
				uown
            }
        }
    }
`;

export {
	OFFER,
	FETCH_PROVIDERS
};