import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../context';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import styles from './ConfirmDialog.css.js';

const ConfirmDialog = ({ open, close, handleSubmission }) => {
    const { appState } = useContext(AppContext);
    const [ isHovering, setHovering ] = useState(false);

    useEffect(() => {},[]);
    const themedStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.hover,
    );

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    };

    return (
        <div style={styles.formContainer}>
            <Slide direction='down' in={open} mountOnEnter unmountOnExit>
                <Paper elevation={10} style={styles.paperContainer}>
                    <div style={styles.headerContainer}>Is this Correct?</div>
                        <div style={styles.modalForm}>
                            <div style={styles.phoneText}>{`Phone Number: ${formatPhoneNumber(appState.user.phone)}`}</div>
                            <div style={styles.subText}>This MUST be valid in order to access your Approval Information later!</div>                        
                            <div style={styles.formButtonRow}>
                                <ButtonGroup style={styles.formButtons}>
                                    <Button style={styles.backButton} onClick={close}>Back</Button>
                                    <Button 
                                        type='submit'
                                        onClick={handleSubmission} 
                                        onMouseEnter={() => setHovering(true)}
                                        onMouseLeave={() => setHovering(false)}
                                        style={themedStyle}
                                    >
                                        Confirm
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                </Paper>
            </Slide>
        </div>
    );
}

export default ConfirmDialog;