import LogRocket from 'logrocket';

const initialAppState = {
    flow_active: false,
    checking_account: null,
    overdrawn: null,
    employment: null,
    income: null,
    credit: null,
    declined: {
        checking: false,
        overdrawn: false,
        income: false,
        employment: false,
        credit: false
    },
    user: {
        first: null,
        last: null,
        email: null,
		phone: null,
        clickId: null,
        uown_app_id: null,
        terrace_app_id: null,
        application_id: null,
        application_token: null,
        application_status: null,
        approval_amount: null,
        sub_provider: null
    },
    qualify: {
        income: null,
        bank: null,
        job: null,
        bankruptcy: null,
        percent: 65,
        step: 1
    }
};

const appStateReducer = (state, action) => {

    switch (action.type) {
            
        case 'SET_STEP':
            return {
                ...state,
                qualify: {
                    ...state.qualify,
                    step: action.payload
                }
            };
        case 'SET_INCOME':
            return {
                ...state,
                qualify: {
                    ...state.qualify,
                    income: action.payload
                }
            };
        case 'SET_BANK':
            return {
                ...state,
                qualify: {
                    ...state.qualify,
                    bank: action.payload.status,
                    // We want the lowest percent saved
                    percent: state.qualify.percent >= action.payload.percent ? action.payload.percent : state.qualify.percent
                }
            };
        case 'SET_JOB':
            return {
                ...state,
                qualify: {
                    ...state.qualify,
                    job: action.payload.status,
                    percent: state.qualify.percent >= action.payload.percent ? action.payload.percent : state.qualify.percent
                }
            };
        case 'SET_BANKRUPTCY':
            return {
                ...state,
                qualify: {
                    ...state.qualify,
                    bankruptcy: action.payload.status,
                    percent: state.qualify.percent >= action.payload.percent ? action.payload.percent : state.qualify.percent
                }
            };
        case 'START_FLOW':
            return {
                ...state,
                flow_active: true
            };

        case 'GO_HOME':
            return {
                ...state,
                flow_active: false
            };

        case 'SELECTED_ANSWER_CHECKING':
            return {
                ...state,
                checking_account: action.payload
            }; 

        case 'DECLINED_CHECKING':
            return {
                ...state,
                declined: {
                    ...state.declined,
                    checking: true
                }
            }; 

        case 'SELECTED_ANSWER_OVERDRAWN':
            return {
                ...state,
                overdrawn: action.payload
            };

        case 'DECLINED_OVERDRAWN':
            return {
                ...state,
                declined: {
                    ...state.declined,
                    overdrawn: true
                }
            }; 

        case 'SELECTED_ANSWER_EMPLOYMENT':
            return {
                ...state,
                employment: action.payload
            };

        case 'DECLINED_EMPLOYMENT':
            return {
                ...state,
                declined: {
                    ...state.declined,
                    employment: true,
                    income: true
                }
            }; 

        case 'SELECTED_ANSWER_INCOME':
            return {
                ...state,
                income: action.payload
            };

        case 'DECLINED_INCOME':
            return {
                ...state,
                declined: {
                    ...state.declined,
                    income: true
                }
            }; 

        case 'SELECTED_ANSWER_CREDIT':
            return {
                ...state,
                credit: action.payload
            };
            
        case 'DECLINED_CREDIT':
            return {
                ...state,
                declined: {
                    ...state.declined,
                    credit: true
                }
            }; 

        case 'FORM_SUBMIT':
            const { clickId, first, last, email, phone } = action.payload;
            LogRocket.identify(clickId, { name: first + ' ' + last, email, phone });
            return {
                ...state,
                user: action.payload
            }; 
        
        case 'USER_APPROVAL':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }; 

		default:
			return {...state};
    }
};

export {
    initialAppState,
    appStateReducer
};