import React, { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from '../UserInfo/Form/SubmitButton';
import styles from './ApprovalCustom.css.js';
import Radium from 'radium';
import LegalDisclaimer from './LegalDisclaimer';
import CurrencyInput from '../UserInfo/Form/Inputs/CurrencyInput';

const stripValue = (value) => {
    return value.replace('$', '').replace(',', '');
};

const Income = Radium(() => {
    const [ income, setIncome ] = useState('');
    const [ isError, setIsError ] = useState(false);
    const methods = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    const { dispatchApp } = useContext(AppContext);

    const onSubmit = (data, e) => {
        e.preventDefault();
        if(income === '' || income.length < 3) {
            setIsError(true);
            return;
        };
        const incomeClean = stripValue(income);
        dispatchApp({type:'SET_INCOME', payload: incomeClean});
        dispatchApp({type:'SET_STEP', payload: 2});
    };

    const handleChange = (e) => {
        setIncome(e.target.value);
        if(isError && income !== '' && income.length > 3) {
            setIsError(false);
        };
    };
    
    return (
        <FormProvider {...methods}>
            <form style={styles.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
                <CurrencyInput 
                name='income' 
                text='Monthly Income' 
                error={isError}
                errorMessage='Income value is required!'
                value={income}
                setValue={handleChange}
                />
                <div style={styles.backContainer}>
                    <SubmitButton disabled={false} loading={false}/>
                </div>
                <LegalDisclaimer />
            </form>
        </FormProvider>
    );
});

export default Income;