const noValueErr = { 
    required: '*Required',
    minLength: {
        value: 3,
        message: 'Min length is 3'
    }
};
const fnameErr = { 
    required: '*Required',
    minLength: {
        value: 2,
        message: 'Min length is 2'
    }
};

const lnameErr = { 
    required: '*Required',
    minLength: {
        value: 2,
        message: 'Min length is 2'
    }
};

const emailErr = { 
    required: '*Required',
    pattern: {
        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: 'Please enter a valid Email Address.'
    }
};

export const clearTel = tel => tel.replace(/[^0-9]/g, "");

const isNotFilledTel = v => {
    const clearedTel = clearTel(v);
    return clearedTel.length < 11 ? 'Please enter a valid Phone Number.' : undefined;
};

const phoneErr = {
    required: '*Required',
    validate: {
        inputTelRequired: isNotFilledTel
    }
};

export default {
    noValueErr,
    fnameErr,
    lnameErr,
    emailErr,
    phoneErr
}