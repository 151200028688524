const styles ={
    offerButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '30px',
        paddingRight: '10px'
    },
    offerButtonHeader:{
        fontSize: '1.4rem',
        // borderBottom: '2px solid rgb(0,179,236)',
        color: 'rgb(0,179,236)',
    },
    offerSubHeader: {
        marginTop: '10px',
        textAlign: 'left',
        fontSize: '1rem'
    },
    offerButtonRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: '10px'
    },
    offerButtonRowSm: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '10px'
    },
    offerButton: {
        width: '60%',
        height: '60px',
        background: 'linear-gradient(0deg, rgba(255,153,58,1) 0%, rgba(255,205,0,1) 50%)',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        border: '2px solid rgb(77,107,137)'
    },
    offerButtonSm: {
        width: '100%',
        height: '50px',
        background: 'linear-gradient(0deg, rgba(255,153,58,1) 0%, rgba(255,205,0,1) 50%)',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        border: '2px solid rgb(77,107,137)'
    },
    offerButtonSponsored: {
        fontSize: '1rem',
        opacity: '0.5'
    },
    hover: {
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.4rem',
    },
};
export default styles;