import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ApprovalCustom.css.js';
import Radium from 'radium';
import { useMediaQuery } from 'react-responsive';

const BackButton = Radium(() => {
    const [ isHovering, setHovering ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { appState, dispatchApp } = useContext(AppContext);
    const { qualify } = appState;

    const themedStyle = Object.assign({},
        styles.backButton,
        isHovering && styles.hover
    );

    const handleClick = () => {
        dispatchApp({type:'SET_STEP', payload: qualify.step - 1});
    };

    return (
        <button
            onMouseEnter={() => setHovering(!isMobile)}
            onMouseLeave={() => setHovering(false)}
            style={themedStyle}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                icon={['fal', 'angle-double-left']}
                style={styles.icon}
            />      
            Back
        </button>
    )
});

export default BackButton;