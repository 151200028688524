import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../context';
import styles from './ApprovalCustom.css';
import Radium from 'radium';
import Income from './Income';
import BankAccount from './BankAccount';
import Job from './Job';
import Bankruptcy from './Bankruptcy';
import Results from './Results';

const ApprovalCustom = () => {
    const [ header, setHeader ] = useState(null);
    const { appState } = useContext(AppContext);
    const { qualify } = appState;

    useEffect(() => {
        switch(qualify.step){
            case 1:
                setHeader('Enter your monthly income.');
                break;
            case 2:
                setHeader('How long has your Bank Account been open?');
                break;
            case 3:
                setHeader('How long have you been Employed?');
                break;
            case 4:
                setHeader('What is your Bankruptcy Status?');
                break;
            case 5:
                setHeader('');
                break;
            default:
                setHeader('Unknown step');
        }
    },[qualify.step]);

    return(
        <div style={styles.container}>
            <div style={styles.formBody}>								
                {qualify.step !== 5 && <h3 style={styles.formTitle}>{header}</h3>}
                {qualify.step === 1 && <Income style={styles.formContent} />}
                {qualify.step === 2 && <BankAccount style={styles.formContent} />}
                {qualify.step === 3 && <Job style={styles.formContent} />}
                {qualify.step === 4 && <Bankruptcy style={styles.formContent} />}
                {qualify.step === 5 && <Results style={styles.formContent} />}
            </div>
        </div>
    )
};

export default Radium(ApprovalCustom);