export default {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        WebkitBoxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
        MozBoxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
        boxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
    },
    isStart: {},
    started: {
        // backgroundColor: 'rgba(216,226,240,0.5)',
        WebkitBoxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
        MozBoxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
        boxShadow: 'inset 0px -4px 6px -6px rgba(0,0,0,1)',
    },
    uown: {
        alignItems: 'flex-start',
    },
    questionBox: {
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            maxWidth: '100vw',
            minWidth: '100vw',
            padding: '0'
        }
    }
}