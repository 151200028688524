import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/chevrons.json';

const Chevrons = () => {

	const defaultOptions = {
		loop: true,
		autoplay: true, 
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	
	return (
		<Lottie 
			className='chevron-lottie'
			options={defaultOptions}
			width={50}
		/>
	);
}

export default Chevrons;
