import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import BotLogo from '../../Icons/BotLogo';
import Phone from '../../Icons/Phone';

const Navbar = () => {
	const { trackingState } = useContext(AppContext);

	const goToBOT = () => {
		const linkout = `https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9308&pid=3254&eid=${trackingState.sid}&uid=${trackingState.eid}&gclid=${trackingState.gclid}`;
		window.open(linkout, '_blank');
	};

	return (
		<div className='navbar-container'>
			<BotLogo width='160px' onClick={goToBOT} className='navbar-logo'/>
			<a className='navbar-phone-container' href={`tel:+1-888-274-7732`}>          
				<Phone width='18px' height='22.5px' />
				888-274-7732                        
			</a>
		</div>
	);
};

export default Navbar;