import React from 'react';
import { 
	Apple,
	Samsung,
	Lg,
	Sony,
	Dell,
	Bestbuy
} from '../../Icons';

const brands = [
    {text: 'Apple', icon: <Apple />}, 
    {text: 'Samsung', icon: <Samsung />}, 
    {text: 'LG', icon: <Lg />}, 
    {text: 'Sony', icon: <Sony />}, 
    {text: 'Dell', icon: <Dell />}, 
    {text: 'Best Buy', icon: <Bestbuy />}
];

export default brands;