const styles = {
    mainContainer: {
        width: '100%',
        minWidth: '800px',
        minHeight: '80vh',
        border: 'none',
        padding: '5vh 0',
        displauy: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mobileMainContainer: {
        padding: '50px 20px',
        maxWidth: '100vw',
        minWidth: '100vw'
    },
    formContent: {
        padding: '20px 0',
        // minWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '@media (maxWidth: 767px)': {
            padding: '10px 0',
        }
    },
    helperText: {
        fontWeight: '500',
        fontSize: '1.4rem',
        lineHeight: '20px',
        color: '#004987',
        // marginTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        textTransform: 'uppercase',
    },
    routingImg: {
        marginTop: '20px',
        width: '100%',
        borderRadius: '20px'
    },
    inputContainer: {
        padding: '0 15%'
    },
    mobileInputContainer: {
        padding: '0 0'
    }
};
export default styles;