export default {
    container: {
        width: '100%',
        minHeight: 'fit-content',
        border: 'none',
        padding: '5vh 0',
        '@media (max-width: 767px)': {
            padding: '40px 0',
        }
    },
    formBody: {
        width: '100%',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    formTitle: {
        fontWeight: '500',
        fontSize: '1.4rem',
        lineHeight: '20px',
        color: '#004987',
        marginTop: '1rem',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        textTransform: 'uppercase',
        '@media (max-width: 767px)': {
            fontSize: '1.55rem',
            lineHeight: '1.5rem',
        }
    }
}