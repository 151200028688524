import React, { useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { ADD_USER_APPROVAL, POST_DORADO } from '../../../utilities/mutations';
import { buildFlexDoradoUser, returnApprovalStatus } from '../../../utilities/helpers';
// import LogRocket from 'logrocket';
import { useMutation } from '@apollo/client';

const ApprovalFlex = () => {
    let history = useHistory();
	const sentToDB = useRef(false);
	const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

    const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

    const handleDecision = (decisionData, addressObj) => {
// Post to Mongo
        addUserApproval({ 
            variables: { 
                clickId: Number(trackingState.hsid),
                phone: user?.phone,
                email: user?.email,
                approvalData: {
                    flex_app_id: String(trackingState.hsid),
                    application_status: decisionData.status,
                    approval_amount: Number(decisionData.amount)
                }
            }
        });
        const userData = Object.assign({},
            user, {
                flex_app_id: String(trackingState.hsid),
                application_status: decisionData.status,
                approval_amount: decisionData.amount
            }
        );
        const doradoBody = buildFlexDoradoUser(userData, trackingState, addressObj);
        console.log('DoradoData:', doradoBody);
        // Post to Dorado
        postUserDorado({ variables: { user: doradoBody }});
        // Save to context
        dispatchApp({type:'USER_APPROVAL', payload: userData});
        sentToDB.current = true;
        if(decisionData.status === 'approved') {
			history.push('/Congratulations');
		} else if(decisionData.status === 'abandoned') {
			history.push('/');
		} else {
			history.push('/DeclinationFlex');
		};
    };

    useEffect(() => {
        window.FlexSDK.Modal({
            orderData: {
                items: [],
                customer: {
                    firstName: user?.first,
                    lastName: user?.last,
                    email: user?.email,
                    phones: {
                        mobile: user?.phone
                    }
                }
            },
            onDecision (data) {
                const decisionDataAtob = atob(data);
                const decisionData = JSON.parse(decisionDataAtob);
                const dataObj = {
                    id: decisionData.summary.customerId,
                    amount: decisionData.summary.spendingLimit/100,
                    status: returnApprovalStatus(decisionData.summary.status)
                };
                const addressObj ={
                    city: decisionData.summary.address.city,
                    state: decisionData.summary.address.region,
                    country: 'US',
                    zip: decisionData.summary.address.postalCode
                };
                handleDecision(dataObj, addressObj);
            }
        }).render('#flex-shopper');
        // eslint-disable-next-line
    },[]);

    return(
        <div style={{ minHeight: '500px' }}>
            <div style={{ minHeight: '100%', minWidth: '300px', marginTop: '200px' }} id='flex-shopper'></div>
        </div>
    )
};

export default ApprovalFlex;
// let data = {
//     decisionData: {
//         summary:{
//             customerId: "61eacaf3906d1d00194980e2",
//             depositAccount:{
//                 paymentType:"ach",
//                 label:"Jpmorgan chase bank n.a.",
//                 lastFour:"3333",
//                 id:"d9726939-7304-492c-9efd-c19d2ea1ddec",
//                 primary:true
//             },
//             email:"testy.approve@flexshopper.com",
//             pendingAgreement:false,
//             isTest:true,
//             firstName:"Ashely",
//             phone:"3033333333",
//             lastName:"Zzelkova",
//             spendingLimit:200000,
//             spendingHold:true,
//             status:"APPROVED",
//             weeklyLimit:8192,
//             address:{
//                 street1:"1298 Paris Ave SE",
//                 city:"Paris",
//                 region:"OH",
//                 postalCode:"44669"
//             }
//         },
//         iat:"1642777523651",
//         signature:"b1b0f6a3d1a101bb4983159b1227493125db4888f9f4a1c881c4ff1de5b241b0"
//     }
// }