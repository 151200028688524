import axios from 'axios';

export const firePreapprovalPixel = async(hsid, sid, amount) => {
	const pixelUrlBase = 'https://www.bkoffers.com/hitstreet/pixel_fire_dynamic_ext.cfm?hsid=';
	const approvalPrefix = '&payout=';
	const approvalValue = [ 9388, 9449, 9469 ].includes(sid) ? '' : amount.toString();
	try {
		await axios({
			method: 'GET',
			headers: {
                'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			url: pixelUrlBase + hsid + approvalPrefix + approvalValue
		});
		console.log('BKpixel fired for hsid: ', hsid);
		return;
	} catch(err) {
		console.error('error occured in BKpixel fire', err);
		return
	};
};

// export const firePixelFB = async() => {
// 	try {
// 		await axios({
// 			method: 'POST',
// 			url: 'https://graph.facebook.com/v10.0/399511550764014/events?access_token=EAAJ0uJcd2ccBADBEmd3uN3AUy9wtRChEDtCyCiTrtJpZAPWbr9cIqrshMiLVEdHUJaIQnkkHmeCNYzLhasZAvPzVrZCZCdAYjhqQH8N2kHn6wojBDDC0YcIdvMWlEACK3eZBUGKJtrJfv0tTl70b6H894rwiaXekFbFtwmHa3uZCLuTHKNyESns1e1gBPK8m8ZD',
// 			body: {
// 				'data': [
// 					{
// 						'event_name': 'Purchase',
// 						'event_time': Date.now(),
// 						'user_data': {
// 							'em': null,
// 							'ph': null
// 						},
// 						'custom_data': {
// 							'currency': 'USD',
// 							'value': '0.00'
// 						}
// 					}
// 				],
// 				"test_event_code": "TEST42758"
// 			}
// 		});
// 		console.log('Fired FB Pixel: SUCCESS');
// 		return;
// 	} catch(err) {
// 		console.log('Fired FB Pixel: FAILED');
// 		console.error(err);
// 		return
// 	};
// };
export const firePixelFB = () => {
	window.fbq('init', '399511550764014');
	window.fbq('track', 'Purchase', {
		value: 0.00,
		currency: 'USD'
	});
	
	console.log('Fired FB Pixel: "Purchase"');
};
export const fireNewPixelFB = () => {
	window.fbq('init', '1934904640043657');
	window.fbq('track', 'Purchase', {
		value: 0.00,
		currency: 'USD'
	});
	
	console.log('Fired New FB Pixel: "Purchase"');
};
export const fireAdWordGtag = (amount) => {
	window.gtag_report_conversion(amount);
};

export const fireBingTag = (event, amount = 1) => {
	switch (event) {
		case 'approval':
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'Application result', {
				'event_category': 'Approvals',
				'event_label': 'Acima approval',
				'event_value': amount
			}, { page_path: window.location.pathname });
			console.log('Bing Tag Fired:', event);
			break;
		default:
			console.log('Bing Tag NOT Fired!', event);
			return;
	};
};