import React, { useState, useRef } from 'react';
import styles from './FlowWrapper.css.js';
import Radium from 'radium';
import { useSpring, config, animated } from 'react-spring';
import useHeight from './utils/useHeight';

const FlowWrapper = ({ children, theme }) => {
    const [ heightOn, setHeightOn ] = useState(false);
    const [ sizingRef, contentHeight ] = useHeight({ on: heightOn });
    const uiReady = useRef(false);

    const activateRef = ref => {
        sizingRef.current = ref;
        if (!heightOn) {
            setHeightOn(true);
        }
    };

    const heightStyles = useSpring({
        immediate: !uiReady.current,
        config: { ...config.stiff },
        from: { height: 0 },
        to: { height: contentHeight },
        onRest: () => (uiReady.current = true)
    });

    if(theme === 'isStart') {
        return <>{children}</>;
    };

    return (
        <animated.div style={{ overflow: 'hidden', ...heightStyles }}>
            <div key='flow-wrapper' ref={activateRef} style={{...styles['wrapper'], ...styles[theme]}}>
                <div key='flow-question-box' style={styles['questionBox']}>
                    {children}
                </div>
            </div>
        </animated.div>
    );
};

export default Radium(FlowWrapper);