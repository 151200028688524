import React, { useContext, useRef } from 'react';
import { AppContext } from '../../../context';
import styles from './ApprovalTerrace.css.js';
import { useHistory } from 'react-router-dom';
import { ADD_USER_APPROVAL, POST_DORADO } from '../../../utilities/mutations';
import { buildDoradoUser,returnApprovalStatus } from '../../../utilities/helpers';
import { useMutation } from '@apollo/client';

const ApprovalTerrace = () => {
    let history = useHistory();
    const sentToMongo = useRef(false);
	const sentToDorado = useRef(false);
    const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

    const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});
    
    const decipherAppStatus = (dataObj) => {
        if(!!dataObj.ApprovedAmount && Number(dataObj.ApprovedAmount) > 0) {
            return 'approved'
        } else {
            return 'abandoned'
        }
    };
    const handleApplicationFinish = async(dataObj) => {
        console.log('status from lender:', dataObj.ApplicationStatus);
        let appData = {
            terrace_app_id: String(dataObj.ApplicationId),
            application_status: returnApprovalStatus(dataObj.ApplicationStatus),
            approval_amount: Number(dataObj.ApprovedAmount),
            sub_provider: dataObj.Lender
        };  
        if(dataObj.ApplicationStatus === 'Delivered') {
            appData = {
                terrace_app_id: String(dataObj.ApplicationId),
                application_status: decipherAppStatus(dataObj),
                approval_amount: dataObj.ApprovedAmount === null ? 0 : Number(dataObj.ApprovedAmount),
                sub_provider: dataObj.Lender
            }
        };
        if(!sentToMongo.current) {
            addUserApproval({ 
                variables: { 
                    clickId: Number(trackingState.hsid),
                    phone: user?.phone,
                    email: user?.email,
                    approvalData: appData
                }
            });
            sentToMongo.current = true;
        };
        const userData = Object.assign({}, user, appData);
        dispatchApp({type:'USER_APPROVAL', payload: userData});
		if(!sentToDorado.current) {
            const doradoBody = buildDoradoUser(userData, trackingState);
            console.log('dorado body post:', doradoBody);
            postUserDorado({ variables: { user: doradoBody }});
            sentToDorado.current = true;
            if(appData.application_status === 'approved') {
                history.push('/Congratulations');
            } else if(appData.application_status === 'abandoned'){
                history.push('/');
            } else if(appData.application_status === 'not_approved'){
                history.push('/Declination');
            } else {
                return;
            };
        };
    };

    window.onmessage = (event) => {
        if(event.origin === 'https://terracefinance-ux-qa.azurewebsites.net') {
            const data = JSON.parse(event.data);
            console.log('Event data from iframe:', data);
            handleApplicationFinish(data);
        };
	};

    const iframeUrl = 'https://cutt.ly/Buy-on-Trust';

    return(
        <div style={{ minHeight: '500px' }}>
            <iframe style={styles.iframeStyle} title='tarrace' id='application-terrace' src={iframeUrl}></iframe>
        </div>
    );
};

export default ApprovalTerrace;