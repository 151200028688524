const style = {
    container: {
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
    },
    topText: {
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '2.2rem',
        padding: '10px 0',
        lineHeight: '1.1',
        marginTop: '0 0 10px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileTopText: {
        fontSize: '1.5rem',
		lineHeight: '1.25',
		margin: '10px 5px 0 5px',
		maxWidth: '375px',
    },
    lowerText: {
        textAlign: 'center',
        fontSize: '2.2rem',
        textTransform: 'uppercase',
        margin: '10px 0 0 0',
        fontWeight: '700',
        display: 'inline-flex',
    },
    mobileLowerText: {
        fontSize: '1.6rem',
		margin: '15px 0',
    },
    ass: {
        fontSize: '1rem',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    phoneContainer: {
        fontWeight: '300',
        fontSize: '0.85rem',
        display: 'inline-flex',
        marginBottom: '20px',
    },
    mobilePhoneContainer: {
        display: 'none'
    },
    heroPhoneIcon: {
        display: 'flex',
        flex: '1',
    },
    heroPhoneText: {
        display: 'inline-flex',
        marginLeft: '10px',
    },
    moreInfo: {
        position: 'fixed',
        bottom: '20px',
    },
    welcomeImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        height: '600px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    mobileWelcomeImage: {
        height: '200px',
		width: '95vw',
		marginTop: '30px',
    },
    boldText: {
        fontWeight: '700',
        fontSize: '140%',
    },
    gotToStore: {
        textDecoration: 'underline',
        textDecorationColor: 'rgb(41, 170, 225)',
        color: 'rgb(41, 170, 225)',
    },
    link: {
        color: 'rgb(41, 170, 225)',
    },
    bbLogo: {
        margin: '10px 0',
    }
};
export default style;