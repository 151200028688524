import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomInput from '../../UserInfo/Form/Inputs/CustomInput';
import SubmitButton from '../../UserInfo/Form/SubmitButton';
import styles from '../RoutingNumber.css.js';
import valid from './errors';
import Radium from 'radium';

const RoutingForm = Radium(({ handleFormSubmit, loading }) => {
    const methods = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    const { isValid, submitCount } = methods.formState;
    const [ isDisabled, setDisabled ] = useState(false);

	useEffect(() => {
		if(submitCount > 0) {
			setDisabled(!isValid);
		};

    },[isValid, submitCount]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        if(isDisabled) return;
        const formData = {
            routing: data.routing,
        };
        handleFormSubmit(formData);
    };
    
    return (
        <FormProvider {...methods}>
            <form style={styles.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
                <CustomInput name='routing' text='Bank Routing' err={valid.routingErr} />
                <SubmitButton disabled={isDisabled} loading={loading}/>
            </form>
        </FormProvider>
    );
});

export default RoutingForm;