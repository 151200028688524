import loader from '../../../assets/gifs/Spin.gif';

const styles = {
    loadingContainer: {
        background: `url(${loader}) center center no-repeat`,
    },
    bgContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    iframeDiv: {
        minHeight: '100vh',
        
    },
    iframeContainer: {
        minWidth: '80vw',
        minHeight: '80vh',
        backgroundColor: 'white',
        marginTop: '5vh'
    },
    iframeContainerMobile: {
        marginTop: '0',
        minWidth: '100vw',
        minHeight: '100vh',
    },
    loader: {
        minWidth: '80vw',
        minHeight: '80vh',
        marginTop: '5vh',
        display: 'flex',
        justifyContent: 'center',
        background: '#ededed',
        // eslint-disable-next-line
        background: '-moz-radial-gradient(center, ellipse cover,  #ededed 0%, #ddf1f9 35%, #a0d8ef 100%)',
        // eslint-disable-next-line
        background: '-webkit-radial-gradient(center, ellipse cover,  #ededed 0%,#ddf1f9 35%,#a0d8ef 100%)',
        // eslint-disable-next-line
        background: 'radial-gradient(ellipse at center,  #ededed 0%,#ddf1f9 35%,#a0d8ef 100%)',
        filter: 'progid',
        alignItems: 'center',
    },
    loaderMobile: {
        marginTop: '0',
        minWidth: '100vw',
        minHeight: '100vh',
    },
    spin: {
        borderRadius: '50%'
    }
};

export default styles;