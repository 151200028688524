import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../../context';
import { firePreapprovalPixel, firePixelFB, fireNewPixelFB, fireBingTag, fireAdWordGtag } from '../../../utilities/pixels';
import { buildDoradoUser } from '../../../utilities/helpers';
import { ADD_SUBSCRIBER, POST_DORADO, ADD_USER_APPROVAL } from '../../../utilities/mutations';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lotties/confetti-cannons.json';
import HeadShake from 'react-reveal/HeadShake';
import { useMutation } from '@apollo/client';
import useMediaQuery from 'react-responsive';
import styles from './ApprovalCustom.css';
import Radium from 'radium';

const Qualified = Radium(({ amount }) => {
    // let history = useHistory();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const hasFired = useRef(false);
    const sentToMongo = useRef(false);
	const sentToDorado = useRef(false);
    const [ windowWidth ] = useState(window.innerWidth);
    const [ windowHeight ] = useState(window.innerHeight);
    const [ isComplete, setConfettiComplete ] = useState(false);
    const { appState, GtagValueEvent, trackingState, dispatchApp } = useContext(AppContext);
    const { hsid, sid } = trackingState;
    const { user } = appState;
    
    const [ addNewSubscriber ] = useMutation(ADD_SUBSCRIBER, {
        onCompleted: (data) => {console.log(data.addNewSubscriber.message)},
        onError: (err) => {console.error(err)}
    });

    const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

    const handleApplicationFinish = async() => {
        const bot_id = new Date().getTime().toString();
        if(!sentToMongo.current) {
            addUserApproval({ 
                variables: { 
                    clickId: Number(trackingState.hsid),
                    phone: user?.phone,
                    email: user?.email,
                    approvalData: {
                        bot_app_id: bot_id,
                        application_status: 'approved',
                        approval_amount: Number(amount)
                    }
                }
            });
            addNewSubscriber({
                variables: { 
                    subscriber: {
                        clickId: Number(trackingState.hsid),
                        first: user?.first,
                        last: user?.last,
                        phone: user?.phone,
                        approval_amount: Number(amount)
                    } 
                }
            });
            sentToMongo.current = true;
        };
        const userData = Object.assign({},
            user, {
            bot_app_id: bot_id,
            application_status: 'approved',
            approval_amount: Number(amount)
        });
        dispatchApp({type:'USER_APPROVAL', payload: userData});
		if(!sentToDorado.current) {
            const doradoBody = buildDoradoUser(userData, trackingState);
            console.log('dorado body post:', doradoBody);
            postUserDorado({ variables: { user: doradoBody }});
            sentToDorado.current = true;
        };
    };


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const handleClick = () => {
        window.location.href = 'https://buyontrust.com/';
    };

    const updateConfettiState = () => {
        setConfettiComplete(true);
    };

    const defaultOptions = {
		loop: false,
		autoplay: true, 
		animationData: animationData,
		rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
		}
    };

    const checkAmount = () => {
        let numberAmount = Number(amount);
        if(numberAmount > 500) {
            firePreapprovalPixel(hsid, sid, amount);
            fireAdWordGtag(amount);
            GtagValueEvent('Approvals', 'Application Result', 'Acima Approval', amount, 'Open Approval Page');
            fireBingTag('approval', amount);
            firePixelFB();
            fireNewPixelFB();
        };
    };

    useEffect(() => {
		if(!hasFired.current && !!amount) {
            checkAmount();
            handleApplicationFinish();
            hasFired.current = true;
        };

		// eslint-disable-next-line
    }, [hasFired.current, amount]);
    
    const rememberTextStyle = Object.assign({}, 
        styles.rememberText,
        isMobile && styles.mobileRememberText
    );
    const specialNoteStyle = Object.assign({}, 
        styles.specialNoteText,
        isMobile && styles.mobileSpecialNoteText
    );
    const nextStepsHeaderStyle = Object.assign({}, 
        styles.nextStepsHeader,
        isMobile && styles.mobileNextStepsHeader
    );
    const nextStepItemStyle = Object.assign({}, 
        styles.nextStepItem,
        isMobile && styles.mobileNextStepItem
    );

    const NextStepItem = ({text, idx}) => (<li key={`next-step__item-${idx}`} style={nextStepItemStyle}>{text}</li>);
    
    return (
        isComplete ? (
            <div style={styles.congratsContainer}>
                <div style={styles.congratsText}>Congratulations!</div>
                {
                amount ? (
                    <div style={styles.subTextContainer}>
                        <div style={styles.congratsSubText}>
                        You've been Pre-qualified for as much as:
                        </div>
                        <div className='approval-amount__countainer'>
                            {`$${amount}`}
                        </div>
                    </div>
                ) : (
                    <div style={styles.subTextContainer}>
                        <div style={styles.congratsSubText}>
                            You've been Pre-qualified!
                        </div>
                        <div style={styles.congratsSubText}>
                            You will receive your pre-qualified amount shortly in your email.
                        </div>
                    </div>
                )
                }
                <div style={specialNoteStyle}>
                    <span>
                    **PLEASE NOTE**<br />
                    Make sure you order all desired items at once!<br />
                    You can only have one lease at a time.
                    </span>
                </div>
                <div style={styles.nextStepsContainer}>
                    <div style={nextStepsHeaderStyle}>Next Steps:</div>
                    <ol type='1' style={styles.nextStepsList}>
                        <NextStepItem text='Shop Our Huge Inventory of Brand Name Electronics.' idx={0}/>
                        <NextStepItem text='Pick a local BestBuy location at checkout.' idx={1}/>
                        <NextStepItem text='Finish the Approval Process.' idx={2}/>
                        <NextStepItem text='Make a small initial payment with your credit/debit card.' idx={3}/>
                        <NextStepItem text='Pickup your order at your local Best Buy Store.' idx={4}/>
                    </ol>
                </div>
                <div style={rememberTextStyle}>
                    Remember, $0 is due when you pick up your merchandise!!
                </div>
                <HeadShake forever timeout={2000} >
                    <button className='shop-now__button' onClick={handleClick}>Shop Now!</button>
                </HeadShake>
                <div style={styles.legal}>
                *The pre-qualification amount displayed is an estimate based on the answers provided.  Final approval is not guaranteed.  Our lenders all have different underwriting requirements that may include, but are not limited to, credit bureau checks, income verification, bank verification and identity verification.
                </div>
            </div>
        ) : (
            <div style={{ marginLeft: '-15vw' }}>
                <Lottie 
                    className='lottie-loader'
                    options={defaultOptions}
                    height={windowHeight}
                    width={windowWidth}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: updateConfettiState,
                        },
                    ]}
                />
            </div>
        )
    )
});

export default Qualified;