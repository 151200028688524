import { useEffect, useContext, useRef } from 'react';
import { AppContext } from '../context';
import { ADD_NEW_USER } from '../utilities/mutations';
import useGeoLocation from './useGeoLocation';
import { useMutation } from '@apollo/client';

const useInsertNewUser = () => {
    const { trackingState } = useContext(AppContext);
	const hasFired = useRef(false);
    const [ ip_address, geoError ] = useGeoLocation();
	const [ addNewUser ] = useMutation(ADD_NEW_USER, { 
        onCompleted: data => console.log(data.addNewUser.message) 
    });

	const createNewUser = async() => {
		const programData = Object.assign({},
				!!trackingState.pid && {pid: Number(trackingState.pid)},
				!!trackingState.oid && {oid: Number(trackingState.oid)},
				!!trackingState.sid && {sid: Number(trackingState.sid)},
				!!trackingState.eid && {eid: trackingState.eid},
				!!trackingState.uid && {uid: trackingState.uid},
				!!trackingState.gclid && {gclid: trackingState.gclid}
			)
		addNewUser({
			variables: {
				visitor: {
					clickId: Number(trackingState.hsid),
					ip_address: ip_address,
					program: programData,
					mailers_sent: []
				}
			}
		});
	};

	useEffect(() => {
		if (!!ip_address && trackingState.hsid && !hasFired.current) {
			createNewUser();
			hasFired.current = true;
		};
		if (geoError && trackingState.hsid && !hasFired.current) {
			createNewUser();
			hasFired.current = true;
		};
		// eslint-disable-next-line
	}, [ip_address, geoError, trackingState.hsid, hasFired]);

	return;
};

export default useInsertNewUser;