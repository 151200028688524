import gql from 'graphql-tag';

const CREATE_APPROVAL = gql`
	mutation createOrder($fname: String!, $lname: String!, $phone: String!, $email: String!) {
        createOrder(input: {
            details: {
                customer: {
                    firstName: $fname,
                    lastName: $lname,
                    emailAddress: $email,
                    cellPhone: $phone
                },
                dealerConfig: {
                    storeName: "Buy On Trust",
                    checkoutFlowType: modal
                },
                items:[]
            }
        }) {
            order {
                id
            }
            orderErrors {
                ... on FatalError {
                    message
                    path
                }
                ... on BadRequest {
                    message
                    path
                }
                ... on UnauthorizedError {
                    message
                    path
                }
            }
        }
    }
`;
// const CREATE_APPROVAL = gql`
// 	mutation createApprovalFirstOrder($fname: String!, $lname: String!, $phone: String!, $email: String!) {
//         createApprovalFirstOrder(input: {
//             details: {
//                 customer: {
//                     firstName: $fname,
//                     lastName: $lname,
//                     emailAddress: $email,
//                     cellPhone: $phone
//                 },
//                 dealerConfig: {
//                     storeName: "Buy On Trust",
//                     checkoutFlowType: modal
//                 },
//                 items:[],
//                 shippingAmnt: "0.00"
//             }
//         }) {
//             orderId
//             orderErrors {
//                 ... on FatalError {
//                     message
//                     path
//                 }
//                 ... on BadRequest {
//                     message
//                     path
//                 }
//                 ... on UnauthorizedError {
//                     message
//                     path
//                 }
//             }
//         }
//     }
// `;

export default CREATE_APPROVAL;