import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './RoutingNumber.css.js';
import numberList from './numberList';
import RoutingForm from './RoutingForm';
import routing from '../../../assets/images/routing.jpeg';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const RoutingNumber = () => {
    const { appState } = useContext(AppContext);
    const { provider } = appState;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    let history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ routingBadBoys, setRoutingNumbers ] = useState(null);

    useEffect(() => {
        let list = numberList[`${provider}`];
        setRoutingNumbers(list);
        console.log('provider:', provider);
        console.log('List:', list);
        // eslint-disable-next-line
    },[provider]);

    const containerStyle = Object.assign({}, 
        styles['mainContainer'],
        isMobile && styles['mobileMainContainer']
    );
    const inputStyle = Object.assign({}, 
        styles['inputContainer'],
        isMobile && styles['mobileInputContainer']
    );

    const handleSubmit = (data) => {
		setLoading(true);
		console.log('data:', data);
		console.log('bad:', routingBadBoys);
        if(!!routingBadBoys && routingBadBoys.includes(data.routing)) {
            history.push('/NotCompatible');
        } else {
            history.push('/Check');
        }
    };

    return(
        <div style={containerStyle}>
            <div style={styles.helperText}>
                Enter your 9 digit bank routing number.
            </div>
            <img src={routing} alt='routing-help' style={styles.routingImg} />
            <div style={inputStyle}>
                <RoutingForm style={styles.formContent} handleFormSubmit={handleSubmit} loading={loading}/>
            </div>
        </div>
    )
};

export default RoutingNumber;