const styles ={
    mainContainer: {
        width: '100%',
        minWidth: '800px',
        minHeight: '80vh',
        border: 'none',
        padding: '10vh 0',
    },
    mobileMainContainer: {
        minWidth: '100vw',
        padding: '50px 20px'
    },
    helperText: {
        fontWeight: '500',
        fontSize: '1.4rem',
        lineHeight: '20px',
        color: '#004987',
        // marginTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        textTransform: 'uppercase',
    },
    mobileHelperText: {
        fontSize: '1.25rem'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '70px'
    },
    button: {
        width: '400px',
        fontWeight: '400',
        padding: '10px',
        fontSize: '1.25rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        textTransform: 'uppercase',
        color: '#fff',
        backgroundColor: 'rgb(35, 160, 221)',
        marginBottom: '20px'
    },
    mobileButton: {
        width: '250px'
    },
    buttonHover: {
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.4rem',
    }
};
export default styles;