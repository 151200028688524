import React, { useEffect, useContext, useRef, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context';
import styles from './Result.css.js';
import { useMediaQuery } from 'react-responsive';

const OfferButton = ({ sid, eid }) => {
    const [ isHovering, setHovering ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleClick = () => {
        const linkout = `https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9443&pid=3347&eid=${sid}&uid=${eid}`;
        window.open(linkout, '_blank');
    };
    const ButtonRowStyle = Object.assign({},
        styles.offerButtonRow,
        isMobile && styles.offerButtonRowSm
    );
    const ButtonStyle = Object.assign({},
        styles.offerButton,
        isHovering && styles.hover,
        isMobile && styles.offerButtonSm
    );

    return (
        <div style={styles.offerButtonContainer}>
            <div style={styles.offerButtonHeader}>Continue Your Search for Lease to Own Electronics</div>
            <div style={styles.offerSubHeader}>Sorry we could not approve you at this time. The following options could help</div>
            <div style={ButtonRowStyle}>
                <button
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)} 
                    onClick={handleClick} 
                    style={ButtonStyle}
                >
                    FIND OTHER OPTIONS
                </button>
                <div style={styles.offerButtonSponsored}>Sponsored Listings</div>
            </div>
        </div>
    )
};

const Decline = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    // let history = useHistory();
    const hasFired = useRef(false);
    const { appState, trackingState, GtagValueEvent } = useContext(AppContext);
    const { user, provider } = appState;
    const { sid, eid } = trackingState;
    const name = user?.first ?? 'Valued Person'

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    // if(!user.application_status) {
    //     history.push('/');
    // };

    useEffect(() => {
		if(!hasFired.current) {
            GtagValueEvent('Approvals', 'Application Result', 'Acima Denial', 0, 'Open Denial Page');
            hasFired.current = true;
        };

		// eslint-disable-next-line
    }, [hasFired]);
    
    // const returnAcima = () => ('www.acimacredit.com/contact or call the Acima general inquiry line 1(800) 742-1789');
    const returnUown = () => ('www.uownleasing.com/contact/ or call the Uown general inquiry line 1(877) 353-8696');
    // const returnWestCreek = () => ('www.westcreekfin.com/contact or call the West Creek general inquiry line 1(844) 937-8275');
    const returnPaytomorrow = () => ('www.paytomorrow.com/support/consumer or call the PayTomorrow general inquiry line 1(866) 418-5857');

    const containerStyle = Object.assign({},
        styles.pageContainer,
        isMobile && styles.mobilePageContainer
    );

    return (
        <div style={containerStyle}>
            <div className='decline__container'>
                <div className='decline__row'>
                    {`Dear ${name},`}
                </div>
                <div className='decline__row'>
                    Unfortunately, we are not able to approve you at this time.  We would love you to join the BuyOnTrust.com family and welcome you to reapply at anytime in the future.  Just be sure to wait at least 48 hours.
                </div>
                <OfferButton sid={sid} eid={eid}/>
                <div className='decline__row'>
                    {`If you believe that you have been declined in error, please visit ${provider === 'paytomorrow' ? returnPaytomorrow() : returnUown()}.` }
                </div>
                <div className='decline__row'>
                    In addition, we recommend calling Experian customer service 1(888) 397-3742, to make sure your soft credit pull information is correct to better avoid denials in the future.
                </div>
                <div className='decline__row'>
                    Thank you from everyone at the BuyOnTrust.com family.
                </div>
            </div>
        </div>
    );
}

export default Decline;