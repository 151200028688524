import React, { useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { ADD_USER_APPROVAL, POST_DORADO } from '../../../utilities/mutations';
import CREATE_APPROVAL from './graphql/CREATE_APPROVAL';
import { buildDoradoUser, returnApprovalStatus } from '../../../utilities/helpers';
import LogRocket from 'logrocket';
import { useMutation } from '@apollo/client';

const ApprovalWestCreek = () => {
	let history = useHistory();
	const sentToDB = useRef(false);
	const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

	window.onmessage = (event) => {
		console.log('westcreek message:', event);
		// if ( event.data === "{\"type\":\"ACIMA_ECOM_IFRAME_CLOSE\"}" && !sentToDB.current) {
		// 	console.log('abandoning application iframe!');
		// 	handleAbandonPost();
		// };
	};

	const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

	const saveApprovalData = (orderId, status, amount) => {
		// Post to Mongo
		addUserApproval({ 
			variables: { 
				clickId: Number(trackingState.hsid),
				phone: user?.phone,
				email: user?.email,
				approvalData: {
					wc_order_id: orderId,
					application_status: status,
					approval_amount: Number(amount)
				}
			}
		});
		const userData = Object.assign({},
			user, {
				wc_order_id: orderId,
				application_status: status,
				approval_amount: amount
			}
		);
		const doradoBody = buildDoradoUser(userData, trackingState);
		console.log('DoradoData:', doradoBody);
		// Post to Dorado
		postUserDorado({ variables: { user: doradoBody }});
		// Save to context
		dispatchApp({type:'USER_APPROVAL', payload: userData});
		sentToDB.current = true;
	};

	const handlePromiseRes = (res) => {
		const status = returnApprovalStatus(res?.approvalStatus ?? res.reason);
		const orderId = res?.orderId;
		const amount = res?.approvalAmount ?? '0';
		if(!sentToDB.current) { saveApprovalData(orderId, status, amount) };
		if(status === 'approved') {
			history.push('/Congratulations');
		} else if(status === 'abandoned') {
			history.push('/');
		} else {
			history.push('/Declination');
		};
	};

	const showModal = async(wcOrderId) => {
		try {
			const response = await window.koalafiSdk.openModal(wcOrderId);
			handlePromiseRes(response);
		} catch(err) {
			console.log('error in wc promise!:', err);
			handlePromiseRes(err);
		};
	};

	const [createOrderId] = useMutation(CREATE_APPROVAL, {
		onCompleted: data => {
            const wcOrderId = data?.createOrder?.order?.id;
			console.log('wc:', wcOrderId);
            showModal(wcOrderId);
        },
		onError: error => {
			console.error('Error during westcreek orderId creation:', error);
			LogRocket.captureException(error);
		},
		skip: !user.phone || !user.first
	});

	useEffect(() => {
		if(!user.phone) {
			history.push('/');
		};
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if(!!user.first) {
			createOrderId({
				variables: {
					fname: user.first,
					lname: user.last,
					email: user.email,
					phone: user.phone
				},
				context: { clientName: 'westcreek' }
			});
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className='page-container'>
			<div id='approval-form' className='acima-container'/>
		</div>
	);
};

export default ApprovalWestCreek;