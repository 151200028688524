import React, { useState } from 'react';

import step1mobile from '../../../assets/images/step1mobile.png';
import step2mobile from '@bit/buyontrust.assets.step2-mobile';
import step3mobile from '@bit/buyontrust.assets.step3-mobile';
import step4mobile from '@bit/buyontrust.assets.step4-mobile';
import step1web from '../../../assets/images/step1_v2.png';
import step2web from '@bit/buyontrust.assets.step2';
import step3web from '@bit/buyontrust.assets.step3';
import step4web from '@bit/buyontrust.assets.step4';

const stepsArray = [
    {src: step1mobile},
    {src: step2mobile},
    {src: step3mobile},
    {src: step4mobile},
];

const Steps = () => {
	const [mobile] = useState(window.innerWidth < 1000);
	
	return (
		mobile ? (
			<div className='mobile-steps-container'>
				{stepsArray.map((step, i) => (
					<img key={`step-img-mobile-${i}`} src={step.src} alt='step-img' className='step-img-mobile'/>
				))}
			</div>        
		) : (
			<div className='web-steps-container'>
				<div className='step-row'>
					<div className='step'>                                   
						<img src={step1web} className='step-img' alt='step-img' />
					</div> 
					<div className='step'>
						<img src={step2web} className='step-img' alt='step-img' />
					</div> 
				</div>
				<div className='step-row'>
					<div className='step'>
						<img src={step3web} className='step-img' alt='step-img' />
					</div> 
					<div className='step'>
						<img src={step4web} className='step-img' alt='step-img' />
					</div> 
				</div>
			</div>
		)        
	);
}

export default Steps;