import React from "react";

function SvgApple(props) {
  return (
    <svg width={37.8} height={42.7} {...props}>
      <image
        width={37.8}
        height={42.7}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA9CAYAAADmgpoeAAAD9ElEQVRogd2aXYhVVRTHf94xDWwYP2PKLMPQEsXK/CAZHBWFKAyGqQdNDH1wpB7EKALnrfBNR/HBBym/BhERQQyVCiJJQzM1BVEQP6tBBvyakQEtl6zLunDncs49+5xzPfdu/3Bh5p5z1l6/u87ZZ+211wARoYY0AfgAmANMBlqBP5K4N7BGmKYAXwGLKmWw2mADgPXAqpDjfyc1XE2w54EfLVpBugZ0JTVeLTCFOg28WOacI2kGyKW5OKEGA39GQKk60wxSDbCNwEsR5/QBP6UZJGuwicAKh/PagFTvoazBNjiccwjYkXagLF/QjQ6z3GXgdeBh2sGyjFhrxPFLwNuVgCJjsBlljulEMRW4W6nBsgR7JeC768DnwALgXiUHS/uM1QGPHGewO0CD/f0rsAvY6nDrDQIexHUsLphO1+8Bs4BxwAgDuwVcBU6a078D/5Vcu8wcPApcCbH/GjAbmAlMsgxlCHDfUqxTwGHg50hPFczhs1JETom7/hGRThFpEZH6CPvviMi3InIyhv2rItIhIo1hdqMiNhdYB7wZJ6wlumV5n6ZRNyzCw2ztNatMEuyiHuALYEuciLXH+AWrrT0i8pxLxHYCn6T4JauhK7bq1ucxcLrf5CGU6lWgGxhFANjH9l7xVTsLfpfeir02vfomfbUstAQ6r+IVdJunUDozNgF/FX9ZiFjOMoP6qrmXXG8AF0qvLjxjrZ5CtQdBURSxX4DmzN1KJ02xxoZZ0IgN9RAKi1aocpZ0+iadvXdHgU33EOxgwOqhn3K2VPBNJ6L8VbDRHoJdjjohV7Sq9Ul3XMAGeQgWqZwt/HzTKBewPg/BxkedoGC3s/GloloQZUzB/vWLKa8mq2CFKmc5l49aHQUWmB17oC/LRU3BznkKpr5vLnfwvMsLr0bVAnwaBvbQJfeqYWn9f3EQGFZr91mdpb0iBbADnoOpOoD9VjTtV37rsu3Up0HbiwumW58SKNWHxRF7wdMsJEiLSivBurH2Vs24l0y6jz20tHa/xhfvy+hrQrZqzxZmFg/VXUizgraRlnsKhe0/5BUEpq2q32frT0WkqeG+gqGwHc1nrO/iWY/AplnXQl5hDSyaPy7JzqfU+qYYCoc+j23A0ppGgmPWfdBPLg0sx2u4DH7Xmjp7Sw+49FK9C1x8Mn6lVnMQFI5g/9uOTHdtMeUrVWfCDsbppWq0dc+8mA48sv4p3SO+af832GaIzmRjYtrT7p6P7BEJl2MvVfFnrWMXzhHrwXq5jK06EXlfRLaLSI+DzUMiMtzFz6RtfTqZfGZdaroq0NtVe6a0lPcb8EOCcsNI6zOZb5VeLWPre1S7AjTN+w7Y62QJeAwcyQchJTJwPAAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default SvgApple;
