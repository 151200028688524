import React, { useState } from 'react';
import Brandbar from './BrandBar';
import MobileBrandBar from './MobileBrandBar';
import HowTo from './HowTo';
import Benefits from './Benefits';

const HowItWorks = () => {
    const [mobile] = useState(window.innerWidth < 500);

    return (
        <div className='how_it_works-container'>
            {mobile ? <MobileBrandBar /> : <Brandbar />}
            <HowTo />
            <Benefits />
        </div>
    );
}

export default HowItWorks;