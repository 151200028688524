import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Radium from 'radium';
import FlowWrapper from '../components/Layout/FlowWrapper';

const RouteWrapper = ({ children }) => {
	let location = useLocation();
    const [ theme, setTheme ] = useState(null);

	useEffect(() => {
		if (location.pathname === '/') {
			setTheme('isStart');
			return;
		};
		if(location.pathname === '/uown') {
			setTheme('uown');
			return;
		};
		setTheme('started');
        // eslint-disable-next-line
    }, [location.pathname]);

	return (
		!!theme ? (
			<FlowWrapper theme={theme}>
				{children}
			</FlowWrapper>
		) : null
	);
};

export default Radium(RouteWrapper);