import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../context';
import { FETCH_PROVIDERS } from '../utilities/queries';
import { useLazyQuery } from '@apollo/client';

const useSetProvider = () => {
	const myURL = new URL(window.location.href);
    const { dispatchTracking, trackingState } = useContext(AppContext);
	const { provider, count, state } = trackingState;
    const [ isRestricted, setRestricted ] = useState(false);

    const [fetchProviders, { data, loading }] = useLazyQuery(FETCH_PROVIDERS, {
		onCompleted: data => {console.log(data.fetchProviderSelection.message)},
        onError: error => {console.error(error)}
	});
    
    const setProvider = (providerInput) => {
        console.log(`Setting provider to context: ${providerInput}`);
		dispatchTracking({ type: 'SET_PROVIDER', payload: providerInput });
    };

    useEffect(() => {
        const bannedStates = ['New Jersey', 'Wisconsin', 'Wyoming', 'Minnesota', 'California'];
		if(bannedStates.includes(state)) {setRestricted(true)};
		// eslint-disable-next-line
	}, [state]);

    useEffect(() => {
		const forcedProvider = myURL.searchParams.get('provider') || null;
		if(!!forcedProvider && !provider) {
			console.log(`Provider set in URL: ${forcedProvider}`);
			setProvider(forcedProvider);
			return;
		};
        if(!provider && !forcedProvider && !loading) {
            console.log('no provider set - fetching...')
            fetchProviders();
        };
        // eslint-disable-next-line
    },[provider]);

    useEffect(() => {
        if(!!data) {
            console.log('Provider response found: setting provider');
			const providers = data?.fetchProviderSelection?.data;
			if(!providers) {
				console.log('Provider data: FAILED');
				setProvider('uown');
				return;
			};
			const acimaPercent = providers.acima;
			const wcPercent = providers.westcreek + acimaPercent;
			const affPercent = providers.aff + wcPercent;
			const flexPercent = providers.flex + affPercent;
			const paytomorrowPercent = providers.paytomorrow + flexPercent;
			const terracePercent = providers.terrace + paytomorrowPercent;
			const uownPercent = providers.uown + terracePercent;
			class Provider {
				constructor(name, percent) {
					this.name = name;
					this.percent = percent;
				}
			};
			const acimaView = new Provider('Acima', acimaPercent);
			const wcView = new Provider('WestCreek', wcPercent);
			const affView = new Provider('AFF', affPercent);
			const flexView = new Provider('FlexShopper', flexPercent);
			const paytomorrowView = new Provider('PayTomorrow', paytomorrowPercent);
			const terraceView = new Provider('Terrace', terracePercent);
			const uownView = new Provider('Uown', uownPercent);
			console.table([acimaView, wcView, affView, flexView, paytomorrowView, terraceView, uownView]);
			console.log('count:', count);
			if(count <= acimaPercent) {
				setProvider('acima');
				return;
			} else if(count > acimaPercent && count <= wcPercent) {
				setProvider('westcreek');
				return;
			} else if((count > wcPercent && count <= affPercent && !isRestricted)) {
				setProvider('aff');
				return;
			} else if(count > affPercent && count <= flexPercent) {
				setProvider('flex');
				return;
			} else if(count > flexPercent && count <= paytomorrowPercent) {
				setProvider('paytomorrow');
				return;
			} else if(count > paytomorrowPercent && count <= terracePercent) {
				setProvider('terrace');
				return;
			} else if(count > terracePercent && count <= uownPercent) {
				setProvider('uown');
				return;
			} else {
				setProvider('bot');
			};
		};
        // eslint-disable-next-line
    }, [data]);

    return;
};

export default useSetProvider;