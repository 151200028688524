import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ApprovalCustom.css.js';
import Radium from 'radium';
import CustomButton from './CustomButton';
import BackButton from './BackButton';
import LegalDisclaimer from './LegalDisclaimer';

const BankAccount = Radium(() => {
    const { dispatchApp } = useContext(AppContext);

    const onSubmit = (data) => {
        dispatchApp({type:'SET_BANK', payload: {
            status: data.text,
            percent: data.value
        }});
        dispatchApp({type:'SET_STEP', payload: 3});
    };
    
    return (
        <div style={styles.formContent} >
            <CustomButton value={0} text='Less than 1 month' buttonClick={onSubmit} />
            <CustomButton value={45} text='1 to 3 months' buttonClick={onSubmit} />
            <CustomButton value={55} text='3 to 6 months' buttonClick={onSubmit} />
            <CustomButton value={65} text='6 to 12 months' buttonClick={onSubmit} />
            <CustomButton value={65} text='Over 12 months' buttonClick={onSubmit} />
            <div style={styles.backContainer}>
                <BackButton />
            </div>
            <LegalDisclaimer />
        </div>
    );
});

export default BankAccount;