import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomInput from './Inputs/CustomInput';
import PhoneInput from './Inputs/PhoneInput';
import SubmitButton from './SubmitButton';
import styles from './Form.css.js';
import valid from './Inputs/errors';
import Radium from 'radium';
import { formatPhone, flattenString } from '../../../../utilities/helpers';


const Form = Radium(({ handleFormSubmit, loading }) => {
    const methods = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    const { isValid, submitCount } = methods.formState;
    const [ isDisabled, setDisabled ] = useState(false);

	useEffect(() => {
		if(submitCount > 0) {
			setDisabled(!isValid);
		};

    },[isValid, submitCount]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        if(isDisabled) return;
        const formData = {
            first: data.first,
            last: data.last,
            email: flattenString(data.email),
            phone: formatPhone(data.phone)
        };
        handleFormSubmit(formData);
    };
    
    return (
        <FormProvider {...methods}>
            <form style={styles.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
                <CustomInput name='first' text='First Name' err={valid.fnameErr} />
                <CustomInput name='last' text='Last Name' err={valid.lnameErr} />
                <CustomInput name='email' text='Email Address' err={valid.emailErr} />
                <PhoneInput name='phone' text='Phone Number' err={valid.phoneErr} />
                <div style={styles.legalText}>{styles.data.legal}</div>
                <SubmitButton disabled={isDisabled} loading={loading}/>
            </form>
        </FormProvider>
    );
});

export default Form;