import React, { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { returnApprovalStatus } from '../../../utilities/helpers';
import Zoom from 'react-reveal/Zoom';
import styles from './ApprovalAFF.css.js';
import loader from '../../../assets/gifs/Spin.gif';
import { useMediaQuery } from 'react-responsive';

const ApprovalAFF = () => {
	let history = useHistory();
	const [isLoading, setLoading] = useState(true);
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

	const handleDecision = async(appData) => {
		try {
			const status = returnApprovalStatus(appData?.status ?? 'unknown');
			const approvalData = {
				aff_app_id: appData?.affApplicationId,
				application_status: status,
				approval_amount: Number(appData?.approvedAmount)
			};
			const userData = Object.assign({}, user, approvalData);
		// Save application context and redirect
			dispatchApp({type:'USER_APPROVAL', payload: userData});
			if(status === 'approved') {
				history.push('/Congratulations');
			} else {
				history.push('/DeclinationAFF');
			};
		} catch(err) {
			console.error('error posting approval data!!:', err);
		}
	}

	window.onmessage = (event) => {
		if(event?.data?.event === 'affPartnerMessage') {
			const sentData = event.data?.affstatus;
			const data = {
				affApplicationId: sentData?.affApplicationId,
				approvedAmount: sentData?.approvedAmount,
				status: sentData?.status
			};
			console.log('app data:', data);
			handleDecision(data);
		}
	};

	const iFrameStyle = Object.assign({},
			styles.iframeContainer, 
			isLoading && { opacity:0 },
			isMobile && styles.iframeContainerMobile
		);
	const loaderStyle = Object.assign({},
			styles.loader,
			isMobile && styles.loaderMobile
		);
	// const stageUrl = `https://qaapp.americanfirstfinance.com/3313/1/apply?continuePost=true&statusCallbackUrl=https://7cv2781k2a.execute-api.us-east-1.amazonaws.com/staging/aff/${trackingState.hsid}`
	const prodUrl = `https://app.americanfirstfinance.com/11207/1/apply?continuePost=true&statusCallbackUrl=https://3um9v9wuv7.execute-api.us-east-1.amazonaws.com/active/aff/${trackingState.hsid}`
	return (
		<div style={styles.bgContainer}>
			<Zoom style={styles.iframeDiv} in={true}>
				<div style={styles.iframeDiv}>
				{isLoading && (
					// eslint-disable-next-line
					<div style={loaderStyle}><img style={styles.spin} src={loader} /></div>
				)}
				<iframe 
					title='AFF Application' 
					src={prodUrl}
					style={iFrameStyle}
					frameBorder='0' 
					onLoad={() => {setLoading(false)}}
				/>
				</div>
			</Zoom>
		</div>
	);
};

export default ApprovalAFF;