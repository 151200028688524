import React from 'react';
import Steps from './Steps';
import GetApproved from '../../Shared/GetApproved';

const HowTo = () => (
	<div className='howto-container'>
		<div className='section-container'>
			<div className='title-text'>Wondering how this whole thing works?</div>
			<div className='content-text'>With an initial payment of just $50, you can afford to shop from all the top brand names. Don't wait, find out how much you qualify for right now!</div>
		</div>
		<div className='how-to-steps'>
			<Steps />
			<div className='howto-preapproval-button'>
				<GetApproved />
			</div>
		</div>
	</div>
);

export default HowTo;