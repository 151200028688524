import React from 'react';
import Lottie from 'react-lottie';
import animationData from './bubble.json';

const Loading = ({ styleOverride }) => {

	const defaultOptions = {
		loop: true,
		autoplay: true, 
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

    const loadingContainer = Object.assign({},
        {
            width: '100%',
            minHeight: '800px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        styleOverride
    );

    const loaderStyle = {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: '999',
    };
	
	return (    
		<div style={loadingContainer}>
			<Lottie 
				className='lottie-loader'
				options={defaultOptions}
                style={loaderStyle}
			/>
		</div>      
	);
};

export default Loading;