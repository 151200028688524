import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../context';
import styles from './CheckMark.css.js';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import animationData from '../../../assets/lotties/green-checkmark.json';

const CheckMark = ({ onCompleted }) => {
    let history = useHistory();
    const { appState } = useContext(AppContext);
    const [ isComplete, setCheckComplete ] = useState(false);

    useEffect(() => {
        if(isComplete) {
            history.push(`/${appState.provider}`)
        };
// eslint-disable-next-line
    },[isComplete]);

    const updateCheckState = () => {
        setCheckComplete(true);
    };

    const defaultOptions = {
		loop: false,
		autoplay: true, 
		animationData: animationData,
		rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
		}
    };

    return(
        <div style={styles.container}>
            <Lottie 
                className='lottie-loader'
                options={defaultOptions}
                eventListeners={[
                    {
                        eventName: 'complete',
                        callback: updateCheckState,
                    },
                ]}
            />
        </div>
    )
};

export default CheckMark;