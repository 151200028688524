import { InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from '@apollo/client/link/context';

// const fetcher = (...args) => {
//     return window.fetch(...args);
// };

const useApolloClient = (uri, customHeaders) => {
    const dbLink = createHttpLink({ uri });
    //Staging
    // const wcLink = createHttpLink({ uri: 'https://application-edge-service.sandbox.koalafi.com/query' });
    //Prod
    const wcLink = createHttpLink({ uri: 'https://application-edge-service.koalafi.com/query' });

    const loggerLink = new ApolloLink((operation, forward) => {
        // console.log(`GraphQL Request: ${operation.operationName}`);
        operation.setContext({
            start: new Date()
        });
        return forward(operation).map((response) => {
            // const responseTime = new Date() - operation.getContext().start;
            // console.log(`GraphQL Response took: ${responseTime}`);
            return response;
        });
    });
    
    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: 2000,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error, operation) => {
                console.log('error occurred:', error);
                console.log('error operation:', operation);
                // const doNotRetryCodes = [500, 400];
                // return !!error && !doNotRetryCodes.includes(error.statusCode);
                return !!error
            },
        }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>{
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        const fullHeaders = Object.assign({},
            headers,
            !!customHeaders && customHeaders
        );
        return { headers: fullHeaders };
    });

    const dbLinks = ApolloLink.from([
		loggerLink,
		retryLink,
		errorLink,
		dbLink
	]);
    const wcLinks = ApolloLink.from([
		loggerLink,
		retryLink,
		errorLink,
		wcLink
	]);

    const client = new ApolloClient({
		link: ApolloLink.split(
            operation => operation.getContext().clientName === 'westcreek',
            // the string "westcreek" can be anything you want,
            // we will use it in a bit
            authLink.concat(wcLinks), // <= apollo will send to this if clientName is "westcreek"
            dbLinks // <= otherwise will send to this
        ),
		cache: new InMemoryCache(),
		// connect to your application's Apollo Client in production
		connectToDevTools: true,
	});
    return client;
}

export default useApolloClient;