const styles = {
    formContainer: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh'
    },
    paperContainer: {
        // padding: '20px 100px',
        width: '100%',
        maxWidth: '100vw',
        margin: '0',
        borderRadius: '5px',
        marginTop: '50px'
    },
    headerContainer: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginTop: '20px',
        borderBottom: '1px solid rgba(15,64,107,0.5)',
        color: 'rgba(15,64,107,0.6)',
        textAlign: 'left',
        paddingLeft: '20px'
    },
    modalForm: {
        maxWidth: '1400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '20px 50px'
    },
    formButtonRow: {
        width: '100%',
        padding: '30px 100px 20px 100px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    backButton: {
        minWidth: '100px',
        height: '40px',
    },
    submitButton: {
        minWidth: '100px',
        height: '40px',
        backgroundColor: '#10406B',
        color: 'white',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    formButtons: {
        width: '100%',
        padding: '20px 100px',
        display: 'inline-flex',
        justifyContent: 'flex-end',
    },
    phoneText: {
        fontSize: '1.6rem',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    subText: {
        marginTop: '20px',
        maxWidth: '450px',
        textAlign: 'center'
    }
};

export default styles;