import React, { memo, forwardRef, useContext } from 'react';
import { AppContext } from '../../../../../context';
import { useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import styles from './Inputs.css.js';
import { useMediaQuery } from 'react-responsive';

const InputField = forwardRef(({ name, text, errors }, ref) => {
    const { appState } = useContext(AppContext);
    const { user } = appState;
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const inputStyle = Object.assign({}, 
        styles['inputContainer'],
        isMobile && styles['mobileInputContainer']
    );

    return (
        <div style={inputStyle}>
            <TextField
                style={styles.formInput} 
                name={name}
                inputRef={ref}
                label={text}
                defaultValue={user?.[name] ?? ''}
                inputProps={{'aria-label': `${name}-input-field`}}
                error={!!errors[name]}
            />
            {errors[name] && (<div style={styles.error}>{errors[name].message}</div>)}
        </div>
    )
});
// we can use React.memo to prevent re-render except form state changed
const NestedInput = memo(full => {
    const { register, name, text, err, errors } = full
    // console.log('props:', full);
    return (
        <InputField 
            name={name} 
            text={text} 
            ref={register(err)}
            errors={errors}
        />
    )
},
    (prevProps, nextProps) =>
        prevProps.formState === nextProps.formState
);

const CustomInput = props => {
    const methods = useFormContext();
    const full = {...methods, ...props};
    return <NestedInput {...full}/>;
};

export default CustomInput;