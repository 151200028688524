import React, { useEffect, useContext, useRef, useState } from 'react';
import { AppContext } from '../../../context';
import styles from './ApprovalCustom.css';
import useMediaQuery from 'react-responsive';
import { buildDoradoUser } from '../../../utilities/helpers';
import { ADD_SUBSCRIBER, POST_DORADO, ADD_USER_APPROVAL } from '../../../utilities/mutations';
import { useMutation } from '@apollo/client';

const OfferButton = ({ sid, eid }) => {
    const [ isHovering, setHovering ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleClick = () => {
        const linkout = `https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9443&pid=3347&eid=${sid}&uid=${eid}`;
        window.open(linkout, '_blank');
    };
    const ButtonRowStyle = Object.assign({},
        styles.offerButtonRow,
        isMobile && styles.offerButtonRowSm
    );
    const ButtonStyle = Object.assign({},
        styles.offerButton,
        isHovering && styles.hover,
        isMobile && styles.offerButtonSm
    );

    return (
        <div style={styles.offerButtonContainer}>
            <div style={styles.offerButtonHeader}>Continue Your Search for Lease to Own Electronics</div>
            <div style={styles.offerSubHeader}>Sorry we could not approve you at this time. The following options could help</div>
            <div style={ButtonRowStyle}>
                <button
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)} 
                    onClick={handleClick} 
                    style={ButtonStyle}
                >
                    FIND OTHER OPTIONS
                </button>
                <div style={styles.offerButtonSponsored}>Sponsored Listings</div>
            </div>
        </div>
    )
};

const HomeLink = () => {
    const { dispatchApp } = useContext(AppContext);
    const [ isHovering, setHovering ] = useState(false);
    const ButtonStyle = Object.assign({},
        styles.link,
        isHovering && styles.hover
    );

    const handleStartOver = () => {
        dispatchApp({ type: 'SET_STEP', payload: 1 });
    };

    return(
        <span
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)} 
            style={ButtonStyle} 
            onClick={handleStartOver}
        >
            Here
        </span>
    )
}

const ResultRow = ({title, value, isRed}) => {

    const ResultStyle = Object.assign({},
        styles.resultText,
        isRed && styles.red
    );

    return(
        <div style={styles.resultRow}>
            <div style={styles.resultHeader}>{title}</div>
            <div style={ResultStyle}>{value}</div>
        </div>
    )
};

const Declined = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const hasFired = useRef(false);
    const sentToMongo = useRef(false);
	const sentToDorado = useRef(false);
    const { appState, trackingState, GtagValueEvent, dispatchApp } = useContext(AppContext);
    const { user, qualify } = appState;
    const { sid, eid, hsid } = trackingState;
    const name = user?.first ?? 'Valued Person'

    const [ addNewSubscriber ] = useMutation(ADD_SUBSCRIBER, {
        onCompleted: (data) => {console.log(data.addNewSubscriber.message)},
        onError: (err) => {console.error(err)}
    });

    const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

    const handleApplicationFinish = async() => {
        const bot_id = new Date().getTime().toString();
        if(!sentToMongo.current) {
            addUserApproval({ 
                variables: { 
                    clickId: Number(hsid),
                    phone: user?.phone,
                    email: user?.email,
                    approvalData: {
                        bot_app_id: bot_id,
                        application_status: 'not_approved',
                        approval_amount: 0
                    }
                }
            });
            addNewSubscriber({
                variables: { 
                    subscriber: {
                        clickId: Number(hsid),
                        first: user?.first,
                        last: user?.last,
                        phone: user?.phone,
                        approval_amount: 0
                    } 
                }
            });
            sentToMongo.current = true;
        };
        const userData = Object.assign({},
            user, {
            bot_app_id: bot_id,
            application_status: 'not_approved',
            approval_amount: 0
        });
        dispatchApp({type:'USER_APPROVAL', payload: userData});
		if(!sentToDorado.current) {
            const doradoBody = buildDoradoUser(userData, trackingState);
            console.log('dorado body post:', doradoBody);
            postUserDorado({ variables: { user: doradoBody }});
            sentToDorado.current = true;
        };
    };

    useEffect(() => {
		if(!hasFired.current) {
            GtagValueEvent('Approvals', 'Application Result', 'Acima Denial', 0, 'Open Denial Page');
            handleApplicationFinish();
            hasFired.current = true;
        };

		// eslint-disable-next-line
    }, [hasFired]);

    const checkIfRed = (value) => {
        const arrayOfRed = [
            'Less than 1 month',
            'Unemployed',
            'In Active Bankruptcy',
            'Planning to File Bankruptcy'
        ];
        return arrayOfRed.includes(value);
    };

    const containerStyle = Object.assign({},
        styles.pageContainer,
        isMobile && styles.mobilePageContainer
    );

    return (
        <div style={containerStyle}>
            <div className='decline__container'>
                <div className='decline__row'>
                    {`Dear ${name},`}
                </div>
                <div className='decline__row'>
                Unfortunately, we are not able to pre-qualify you at this time. We would love you to join the BuyOnTrust.com family and welcome you to reapply at anytime in the future. 
                </div>
                <OfferButton sid={sid} eid={eid}/>
                <div className='decline__row'>
                Do you believe you’ve been declined in error?  
                Please review your answers to our pre-qualification form below.  
                Please review any answers highlighted in red.  
                If you answered them incorrectly, simply start our pre-qualification flow again by clicking <HomeLink />
                </div>
                <div className='decline__row'>
                    Thank you from everyone at the BuyOnTrust.com family.
                </div>
                <div style={styles.resultContainer}>
                    <ResultRow title='Income:' value={qualify.income} isRed={checkIfRed(qualify.income)}/>
                    <ResultRow title='Bank Account:' value={qualify.bank} isRed={checkIfRed(qualify.bank)}/>
                    <ResultRow title='Employment:' value={qualify.job} isRed={checkIfRed(qualify.job)}/>
                    <ResultRow title='Bankruptcy:' value={qualify.bankruptcy} isRed={checkIfRed(qualify.bankruptcy)}/>
                </div>
            </div>
        </div>
    );
};

export default Declined;