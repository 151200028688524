import React, { useContext } from 'react';
import { AppContext } from '../../../context';

import { useHistory } from 'react-router-dom';
import BwBotLogo from '../../Icons/BwBotLogo';

const Footer = () => {
	let history = useHistory();
	const { dispatchApp } = useContext(AppContext);

	const goHome = () => {
		dispatchApp({ type: 'GO_HOME' });
		history.push('/');
		window.scrollTo(0,0);
	};

	return (
		<div className='footer-container'>
			<div className='container-item footer-left'>
				<div className='footer-left-child'>
					<BwBotLogo onClick={goHome}/>
					<span>&#9400; 2020 Buy On Trust</span>
				</div>
			</div>
			<div className='container-item footer-right'>
				<div>
					<span className='footer-right-child-title'>Contact Us</span>
					<p className='footer-right-child-text'>
						1434 Spruce St, Suite 100 <br />
						Boulder, CO 80302 <br />
						(888)274-7732
					</p>
				</div>
				<div>
					<span className='footer-right-child-title'>Accounts & Orders</span>
					<p className='footer-right-child-text'>
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/login.php?from=wishlist.php%3F' className='footer-link'>Wishlist</a><br />
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/login.php' className='footer-link'>Login or Sign Up</a><br />
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/shipping-returns/' className='footer-link'>Shipping & Returns</a>
					</p>
				</div>
				<div>
					<span className='footer-right-child-title'>Quick Links</span>
					<p className='footer-right-child-text'>
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/how-it-works/' className='footer-link'>How it Works</a><br />
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/privacy-policy/' className='footer-link'>Privacy Policy</a><br />
						<a target='_blank' rel='noopener noreferrer' href='https://buyontrust.com/terms-of-service/' className='footer-link'>Terms of Service</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;