import React, { useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { ADD_USER_APPROVAL, POST_DORADO } from '../../../utilities/mutations';
import { buildDoradoUser, returnApprovalStatus } from '../../../utilities/helpers';
import { useMutation } from '@apollo/client';
import LogRocket from 'logrocket';

const ApprovalAcima = () => {
	let history = useHistory();
	const sentToMongo = useRef(false);
	const sentToDorado = useRef(false);
	const { appState, dispatchApp, trackingState } = useContext(AppContext);
	const { user } = appState;

	const [ postUserDorado ] = useMutation(POST_DORADO, {
        onCompleted: data => {console.log(data.postUserDorado.message)},
        onError: error => {console.error(error)}
    });

	window.onmessage = (event) => {
		if ( event.data === "{\"type\":\"ACIMA_ECOM_IFRAME_CLOSE\"}" && !sentToDorado.current) {
			console.log('abandoning application iframe!');
			handleAbandonPost();
			sentToDorado.current = true;
		};
	};

	
	const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

	const handleAbandonPost = () => {
		const abandonUser = Object.assign({},
			user,
			{
				application_id: '',
				application_status: 'abandoned',
				approval_amount: '0'
			}
		);
		const doradoBody = buildDoradoUser(abandonUser, trackingState);
		postUserDorado({ variables: { user: doradoBody } });
		addUserApproval({ 
			variables: { 
				clickId: Number(trackingState.hsid),
				phone: user?.phone,
				email: user?.email,
				approvalData: {
					application_id: '',
					application_status: 'abandoned',
					approval_amount: 0,
					created: new Date()
				}
			}
		});
		history.push('/');
	};

	const startAcimaClient = async() => {
		try{
			// <--PRODUCTION-->
			const acima = new window.Acima.Client({ merchantId: 'ecom-merchant-3ebfb793-1a0f-4a4a-b9e9-59d3feeff4c9' });
			// <--SANDBOX-->
			// const acima = new window.Acima.Client({ merchantId: 'ecom-merchant-22cfe700-6383-49ab-b284-338faa096d99' });
			const AcimaRes = await acima.preapproval({
				customer: {
					firstName: user.first,
					middleName: String,
					lastName: user.last,
					phone: user.phone, // 10-digit phone number
					email: user.email,
					address: {
						street1: String,
						street2: String,
						city: String,
						state: String,
						zipCode: String, // 5-digit zip only; e.g. "12345"
					},
				}
			});
			if(!AcimaRes) {
				console.log('Acima application interrupted!');
				return;
			};
			// Send data to mongo
			if(!sentToMongo.current) {
				addUserApproval({ 
					variables: { 
						clickId: Number(trackingState.hsid),
						phone: user?.phone,
						email: user?.email,
						approvalData: {
							application_id: AcimaRes?.applicationId,
							application_status: returnApprovalStatus(AcimaRes?.appStatus),
							approval_amount: AcimaRes?.approvalAmount,
							created: new Date()
						}
					}
				});
				sentToMongo.current = true;
			};
			// Send appropriate data to Dorado
			const userData = Object.assign({},
				user, {
				application_id: AcimaRes?.applicationId,
				application_status: AcimaRes?.appStatus,
				approval_amount: AcimaRes?.approvalAmount
			});
			dispatchApp({type:'USER_APPROVAL', payload: userData});
			if(!sentToDorado.current) {
				const doradoBody = buildDoradoUser(userData, trackingState);
				console.log('dorado body post:', doradoBody);
				postUserDorado({ variables: { user: doradoBody }});
				sentToDorado.current = true;
				if(AcimaRes?.appStatus === 'Approved') {
					history.push('/Congratulations');
				} else {
					history.push('/Declination');
				};
			};
			return;
		} catch(err) {
			console.error('Error during application response:', err);
			LogRocket.captureException(err);
		};
	};

	useEffect(() => {
		if(!user.phone) {
			history.push('/');
		} else {
			startAcimaClient();
		}
		// eslint-disable-next-line
	}, [user]);

	return (
		<div className='page-container'>
			<div id='acima-form' className='acima-container'/>
		</div>
	);
};

export default ApprovalAcima;