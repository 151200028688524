import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import Navbar from './Layout/Navbar';
import HowItWorks from './Layout/HowItWorks';
import Footer from './Layout/Footer';
import Routes from '../Routes';
import useSetNewUser from '../hooks/useSetNewUser';
import useInsertNewUser from '../hooks/useInsertNewUser';
import useSetProvider from '../hooks/useSetProvider';

const App = () => {
	useSetNewUser();
	useInsertNewUser();
	useSetProvider();
	
	const componentIsMounted = useRef(true);

	useEffect(() => {
		if (componentIsMounted.current) {
			ReactGA.initialize('UA-49186805-10');
			ReactGA.set({ page: window.location.pathname });
			ReactGA.pageview('Welcome Page');
		};

		return () => { componentIsMounted.current = false };
        // eslint-disable-next-line
	}, []);

	return (
		<div className='app-container'>
			<Navbar />
			<Routes />
			<HowItWorks />
			<Footer />
		</div>
	);
}

export default App;
