import React from "react";

function SvgDell(props) {
  return (
    <svg width={84} height={25.9} {...props}>
      <image
        data-name="Vector Smart Object"
        width={84}
        height={25.9}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAlCAYAAACJdC37AAAFGElEQVR4nO2ceYiXRRjHP5vWpm6UkbQkXVS0bIfdUgoZdl9adEDbsXYXFVakkRURWlFasUJErWFttkn/aMcaJmJQZIFW1NbapSGGgXaQbWZbEwPfF16W3fWdeec9fm99YGGZfWfmmfc788wzx7t1xhgqwHDgTuAO4F5gURUaFQIr8F4pytkG9BXchuOB54FjY2lLgZuBzSnL3hUY5fB8n96JK6NUV1L+AP5M9KxJz3ZjzAZjzFJjzExjzHjrFXL4GWmMmWeM6RukBVuNMS0p7Zjq+HZWedazxLGe6UnL3sWjt/WnHjgQuBB4DFgNdAM3ASMClD8QZ6mOu4BhgzyzN/AysARozMiO0hNC4IFoBp4FvgDOC1juGM2vbwMHJcwzRZ2hJaAdNUNWAkdYEd4E5g8x0pJQB1wD9ABXeOT/x85GqVpSo2QtcMRtcpUjPfIeArwDLJTbdeUloAl4JZZvrDpN5clLYMv5QIfDi7VLn5nAZ8Bkj/q+A87QyN+qtN2B2cB6YCVwsEe5NUWeAlsuBmYkeO5EYI2CNtdA7W/gceAoYEUs3XaSz4FZWpJMUue5vYD3kBtFNOyhIQKkBuApReJHe5S9Rp3DjvxepY2Rm14hdx/Hrj/bgHeBw/yaU26KENi6yUcHSD9H0e50D7usmHcD44GPlWangmkKzK7aSf6JwKcqI00wWDqKck2Xx0bTvkAn0AUc4FHWcuBI4Em5Z8vhmmNfcAjM7FTwBHC1hw2lZbijYYuBV/V7vcSZAFzkuNVmR9f1wNfAXGC0xwvaoo2OjlhavdyznWd3cyxvHXAd8L6HLaXFVeAeLXfitGk0dmr+S8qMFB6kQ+JuiaWdqs2VJseyoqDsYWB7LN16k1s87SsNoVz0t8BpwFcOeXzqtsubM+VGI3GtC14ArPIQ1867JwH3xcQdpjigW3XVNCHn4N91ZJcls7XpEXGlvMq1jnXuAO4HTgDWxtKPAz5UJN+QcVtyIXSQtQz4IUPDF2iKOFnBVYeWQS7YJdg4YE7sqNMul+YBH+n4sTK4zsE7w2iZsl+GL2iKflzpVfDVpr3pCHsY8oxnBF96QgtMbIOhTKxU1L4+ZlOjxL60tiUcmizWwT4HAlnxK3AjcHpM3Drd9uipurhkMIJHKCotA68DtwKbYrYcATwHnFISGzMn9Ai2o2WPgtu0TWfGdp6OxI1OkT75L4lLYIHtyH0kYHm+NOjUan/ln6xTo1kZxRylJoTAdmOgVetTlwP9Dbr9mAWXaI5dplOkQ2tLlnC49uip/Y769pHL89lLXqw7zC2KZkMHZ7aznZ0i/4/AW46bKE26eZKEduA9T9vsFHRMoicDXJv1pTl2vbPR4+polrQbY0Z7XJt1oTXFtdnEFHVc2KkblxGb5R3s1uNPBdmE9tInac38c4F2BKMIge0+8IOD/G2RljJZzc2D0acoe5xud1SGIgS257XfDPH3vEfzan328kC/48JKkLfA1jU/nfDZrEfzb/pYbYIu41WSPAXu0nLKhWg0t2rbMRRvqPPM73fwUDnyEniuvl3a4Zn/RQnSldIOu/S5TLZsTFlWTZC1wPbO1QXAPbELcb5s0tHeNM/R3K516mvhmld+shJ4rdxqs75NCslCx9G8Tve1bgB+qQFNgpJ2b7ZXm/vfA19K2OX6PUui0dyqoG3PAer6S19GzEn8sXQFqcK/cBirI8BzY2kfaMR2F2hXKajCNznxuXmjvmSc+L+4APAv7+1TcMydWWsAAAAASUVORK5CYII="
      />
    </svg>
  );
}

export default SvgDell;
