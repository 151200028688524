import React from 'react';
import Slider from 'react-slick';
import brands from './brands';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MobileBrandBar = () => {
	const settings = {
		className: 'carousel-container',
		arrows: false,
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 2000,
		cssEase: 'linear'
	};
	
	return (
		<div className='brandbar-container'>
			<div className='brandbar-title'>
				Get all of the top brands
			</div>
			<Slider {...settings}>
				{brands.map((brand, idx) => (
					<div key={`carousel-brand-item__${idx}`}>
						<div className='brand-item'>
							{brand.icon}
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
}

export default MobileBrandBar;