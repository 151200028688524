import React from 'react';
import brands from './brands';

const BrandBar = () => (
	<div className='brandbar-container'>
		<div className='brandbar-title'>
			Get all of the top brands
		</div>
		<div className='carousel-container'>		
			<div className='desktop-brands'>
				{brands.map((brand, idx) => {
					return (
						<div key={`brand-item__${idx}`} className='brand-item'>
							{brand.icon}
							<div className='brand-item-text'>{brand.text}</div>
						</div>
					)
				})}
			</div>
		</div>
	</div>
);


export default BrandBar;