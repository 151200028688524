import React, { useState } from 'react';
import GetApproved from '../../Shared/GetApproved';
import phone_cart from '../../../assets/images/phone_cart.png';

const Benefits = () => {
	const [mobile] = useState(window.innerWidth < 1000);

	return (
		<div className='benefits-container'>
	
			<div className='section-container'>
				<div className='title-text'>Flexible Lease Ownership Plans Designed To Fit Your Budget</div>
				<div className='content-text'>With an initial payment of just $50, you can afford to shop from all the top brand names. Don't wait, find out how much you qualify for right now!</div>
			</div>
			
			<div className={`${mobile ? 'benefits-mobile':'benefits-desktop'}`}>
				{mobile ? (
					<>
					<div className='benefit'>
						<div className='title-text-offset'>SIMPLICITY</div>
						<div className='content-text content-subtext'>
							Payoff over 12 months and get a flexible payment schedule timed to your payroll dates.
						</div>
					</div>
					<div className='benefit'>
						<div className='title-text-offset'>AFFORDABILITY</div>
						<div className='content-text content-subtext'>
							Payoff within 90 days and you can simply close your account for the amount financed + $10.
						</div>					
					</div>
					<div className='benefit'>
						<div className='title-text-offset'>FLEXIBILITY</div>
						<div className='content-text content-subtext'>
							Payoff over 12 months and get a flexible payment schedule timed to your payroll dates.
						</div>
					</div>
					<img src={phone_cart} alt='shopping-cart-img' style={{ width: '100%' }}/>
					<div className='fine-print'>
						{/* <p>*No Credit Needed does not mean or imply that no inquiry will be made of credit history or creditworthiness. It means that this is not a credit transaction. We may check credit history and creditworthiness, but no established credit history is necessary.</p> */}
						<p>*Approval is possible without a FICO® Score, but credit will be checked.</p>
						<p>Not all applicants will be approved. Actual lease terms will be in the lease agreement. Not available in MN, NJ, WI & WY.</p>
						<p>Subject to approval and standard underwriting criteria. Approval is possible without a FICO® Score, but we may check credit. The transaction advertised is a lease, lease-to-own or rent-to-own program, or rental-purchase agreement. For example, instead of paying interest, you will pay rental fees on top of the retail price. The lessee will not own the property until the Total Cost of Ownership is paid either by payment of the total of payments over the full term of the agreement or by prepayment as provided for by law. Other fees may apply, such as delivery fees and optional Liability Damage Waiver fees. Acquiring ownership by leasing costs more than the retailer’s cash price. Terms and conditions subject to change at any time.</p>
					</div>
					</>
				) : (
					<>
					<div className='web-benefits-container'>
						<div className='benefits-left-container' >
							<img src={phone_cart} alt='shopping-cart-img' style={{ width: '100%', maxWidth: '550px', maxHeight: '550px'}} />
						</div>
						<div className='benefits-right-container' >
							<div className='benefit'>
								<div className='title-text-offset'>SIMPLICITY</div>
								<div className='content-text content-subtext'>
									Payoff over 12 months and get a flexible payment schedule timed to your payroll dates.
								</div>
							</div>
							<div className='benefit'>
								<div className='title-text-offset'>AFFORDABILITY</div>
								<div className='content-text content-subtext'>
									Payoff within 90 days and you can simply close your account for the amount financed + $10.
								</div>
							</div>
							<div className='benefit'>
								<div className='title-text-offset'>FLEXIBILITY</div>
								<div className='content-text content-subtext'>
									Payoff over 12 months and get a flexible payment schedule timed to your payroll dates.
								</div>
							</div>
							<GetApproved isOffset={true} /> 
						</div>
					</div>
					<div className='fine-print'>
						{/* <p>*No Credit Needed does not mean or imply that no inquiry will be made of credit history or creditworthiness. It means that this is not a credit transaction. We may check credit history and creditworthiness, but no established credit history is necessary.</p> */}
						<p>*Approval is possible without a FICO® Score, but credit will be checked.</p>
						<p>Not all applicants will be approved. Actual lease terms will be in the lease agreement. Not available in MN, NJ, WI & WY.</p>
						<p>Subject to approval and standard underwriting criteria. Approval is possible without a FICO® Score, but we may check credit. The transaction advertised is a lease, lease-to-own or rent-to-own program, or rental-purchase agreement. For example, instead of paying interest, you will pay rental fees on top of the retail price. The lessee will not own the property until the Total Cost of Ownership is paid either by payment of the total of payments over the full term of the agreement or by prepayment as provided for by law. Other fees may apply, such as delivery fees and optional Liability Damage Waiver fees. Acquiring ownership by leasing costs more than the retailer’s cash price. Terms and conditions subject to change at any time.</p>
					</div>
					</>
				)}
			</div>
		</div>
	);
}

export default Benefits;