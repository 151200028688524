import React, { useState } from 'react';
import styles from './CheckingAccount.css.js';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Button = ({ text, handleClick }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isHovering, setHovering] = useState(false);
    const buttonStyle = Object.assign({}, 
        styles['button'],
        isMobile && styles['mobileButton'],
        isHovering && styles['buttonHover']
    );

    return(
        <div
            style={buttonStyle}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={handleClick}
        >
            {text}
        </div>  
    )
};

const CheckingAccount = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    let history = useHistory();

    const containerStyle = Object.assign({}, 
        styles['mainContainer'],
        isMobile && styles['mobileMainContainer']
    );
    const textStyle = Object.assign({}, 
        styles['helperText'],
        isMobile && styles['mobileHelperText']
    );

    const handleYes = () => {
        history.push('/Routing');
    };

    const handleNo = () => {
        history.push('/NoChecking');
    };

    return (
        <div style={containerStyle}>
            <div style={textStyle}>Do you have a Checking Account?</div>
            <div style={styles.buttonContainer}>
                <Button 
                    text='Yes'
                    handleClick={handleYes}
                />
                <Button 
                    text='No'
                    handleClick={handleNo}
                />
            </div>
        </div>
    )
};

export default CheckingAccount;