import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../context';
import GetApproved from '../../Shared/GetApproved';
import SvgBbLogo from '../../Icons/BbLogo';
import welcomeImage from '../../../assets/images/hero_img.png'
import Phone from '../../Icons/Phone';
import Chevrons from '../../Shared/Chevrons';
import Zoom from 'react-reveal/Zoom';
import styles from './Landing.css.js';
import { useMediaQuery } from 'react-responsive';

const Landing = () => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isTiny = useMediaQuery({ maxHeight: 600 });
	const { trackingState } = useContext(AppContext);
	const [ height ] = useState(window.innerHeight);
	const [ scrollTop, setScrollTop ] = useState(0);
	const [ showHero, setShowHero ] = useState(false);

	useEffect(() => {
		const onScroll = e => {
			setScrollTop(e.target.documentElement.scrollTop);
		};
		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [scrollTop]);
	
	useEffect(() => {
		if(isMobile && scrollTop > 30) {
			setShowHero(true);
			return;
		};

		if (height > 700) {
			setShowHero(true);
			return;
		};

		if (!isMobile && scrollTop > 200) {
			setShowHero(true);
			return;
		};

		return () => setShowHero(false);
	}, [height, scrollTop, isMobile]);

	const renderFullText = () => (
		<><span>Get Approved for Up to <b style={styles.boldText}>$5,000</b> in</span>
		<span>Brand Name Electronics From</span></>
	);
	
	const renderMobileText = () => (
		<><span>Get Approved for Up to</span>
		<span><b style={styles.boldText}>$5,000</b> in</span>
		<span>Brand Name Electronics</span>
		<span>From</span></>
	);
	
	const topTextStyle = Object.assign({},
		styles.topText,
		isMobile && styles.mobileTopText
	);

	const lowerTextStyle = Object.assign({},
		styles.lowerText,
		isMobile && styles.mobileLowerText
	);

	const phoneContainerStyle = Object.assign({},
		styles.phoneContainer,
		isMobile && styles.mobilePhoneContainer
	);

	const welcomeImageStyle = Object.assign({},
		styles.welcomeImage,
		isMobile && styles.mobileWelcomeImage
	);

	return (
		<div style={styles.container}>
			<div style={topTextStyle}>
				{ isMobile ? renderMobileText() : renderFullText() }
			</div>
			<SvgBbLogo 
				setWidth={isTiny ? '101px':'145px'}
				setHeight={isTiny ? '59.4':'84.8'}
				style={styles.bbLogo}
			/>
			<a 
				style={styles.gotToStore} 
				rel="noopener noreferrer"
				target='_blank'
				href={`https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9308&pid=3254&eid=${trackingState.sid}&uid=${trackingState.eid}&gclid=${trackingState.gclid}`}>
				<div style={styles.link}>Preview our Store{isMobile && <br/>}with over 81,000 items!</div>
			</a>
			<div style={lowerTextStyle}>
				No credit needed!<span style={styles.ass} >*</span>
			</div>
			<GetApproved />
			<div style={phoneContainerStyle}>
				<Phone style={styles.heroPhoneIcon} />
				<span style={styles.heroPhoneText}>Questions? Call us! 888-274-7732</span>
			</div>
			{!showHero && (
				<div style={styles.moreInfo}>
					<Chevrons />
				</div>
			)}
			<Zoom in={showHero}>
				<img src={welcomeImage} alt='welcome-img' style={welcomeImageStyle}/>
			</Zoom>
		</div>
	);
}

export default Landing;