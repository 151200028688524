import axios from 'axios';
import { setCookie } from './helpers';

const buildHitStreetLink = (payload) => {
    const hitStreetLink = 'https://bkoffers.com/hitstreet/hit_count_hsid2.cfm?' +
        'offer_id=' + payload.OID + '&' +
        'program_id=' + payload.PID + '&' +
        'hsid=0&' +
        'eid=' + payload.EID + '&' +
        'oid=' + payload.OID + '&' +
        'pid=' + payload.PID + '&' +
        'sid=' + payload.SID + '&' +
        'uid=' + payload.UID;
    return hitStreetLink;
};

export const sendHitStreetHSID = async(payload) => {
    let fetchLink = buildHitStreetLink(payload);
    if(payload.HSID !== 0) {
        console.log('HSID found in url params:', payload.HSID);
        setCookie('hsid', payload.HSID, 3);
        return payload.HSID;
    };
    try{
        const res = await axios({
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            url: fetchLink
        });
        if (res.status !== 200) {
            const backup = Date.now();
            console.log('Error occured on HitStreet; Fallback ClickID created:', backup);
            setCookie('hsid', backup, 3);
            return backup;
        };
        console.log('New HSID set:', res.data);
        setCookie('hsid', res.data, 3);
        return res.data;
        
    } catch(err) {
        return {status: 'failed', message: err};
    }
};

const buildAcimaLink = (payload) => {
    const hitStreetLink = 'https://bkoffers.com/hitstreet/hit_count_hsid2.cfm?' +
        'offer_id=' + payload.oid + '&' +
        'program_id=' + payload.pid + '&' +
        'hsid=0&' +
        'eid=' + payload.eid + '&' +
        'oid=' + payload.oid + '&' +
        'pid=3341&' +
        'sid=9435&' +
        'uid=' + payload.uid;
    return hitStreetLink;
};
const buildWestCreekLink = (payload) => {
    const hitStreetLink = 'https://bkoffers.com/hitstreet/hit_count_hsid2.cfm?' +
        'offer_id=' + payload.oid + '&' +
        'program_id=' + payload.pid + '&' +
        'hsid=0&' +
        'eid=' + payload.eid + '&' +
        'oid=' + payload.oid + '&' +
        'pid=3342&' +
        'sid=9434&' +
        'uid=' + payload.uid;
    return hitStreetLink;
};
const buildAffLink = (payload) => {
    const hitStreetLink = 'https://bkoffers.com/hitstreet/hit_count_hsid2.cfm?' +
        'offer_id=' + payload.oid + '&' +
        'program_id=' + payload.pid + '&' +
        'hsid=0&' +
        'eid=' + payload.eid + '&' +
        'oid=' + payload.oid + '&' +
        'pid=3356&' +
        'sid=9466&' +
        'uid=' + payload.uid;
    return hitStreetLink;
};

export const sendHitStreetApplication = async(payload, provider) => {
    let fetchLink;
    if(provider === 'acima') {
        fetchLink = buildAcimaLink(payload);
    };
    if(provider === 'westcreek') {
        fetchLink = buildWestCreekLink(payload);
    };
    if(provider === 'aff') {
        fetchLink = buildAffLink(payload);
    };
    try{
        const res = await axios({
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            url: fetchLink
        });
        return res.data;
    } catch(err) {
        return {status: 'failed', message: err};
    }
};

const exportToJsonFile = (jsonData, name) => {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

    let exportFileName = `${name}.json`;

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileName);
    linkElement.click();
};

export const printDataReport = async() => {
    try{
        const res = await axios({
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            url: 'http://localhost:4000/print'
        });
        
        console.log('Print Res:', res.data);
        exportToJsonFile(res.data, '24Hour Report');
        return res.data;
        
    } catch(err) {
        return {status: 'failed', message: err};
    }
};