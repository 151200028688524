import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../../context';
import { ADD_USER_OPTIN, ADD_APP_START } from '../../../utilities/mutations';
import { useHistory } from 'react-router-dom';
import styles from './UserInfo.css.js';
import Radium from 'radium';
import Form from './Form';
import LogRocket from 'logrocket';
import { useMutation } from '@apollo/client';
import { sendHitStreetApplication } from '../../../utilities/middleware';
import { isFirstStart } from '../../../utilities/helpers';
import ConfirmDialog from './ConfirmDialog';

const UserInfo = Radium(() => {
    let history = useHistory();
	const logRocketInit = useRef(false);
	const [ loading, setLoading ] = useState(false);
	const [ showDialog, setShowDialog ] = useState(false);
	const { trackingState, dispatchApp, GtagEvent } = useContext(AppContext);
	const { hsid, pid, sid, oid, eid, uid, provider } = trackingState;

	const [addAppStart] = useMutation(ADD_APP_START, { 
		onCompleted: data => {console.log(`${data.addAppStart.message} - User:${hsid}`)},
        onError: error => {console.error(error)}
	});

	const openProviderModal = () => {
		sendHitStreetApplication(trackingState, provider);
		const sendStart = isFirstStart(provider);
		if(sendStart) {
			addAppStart({
				variables: { 
					provider,
					pid,
					sid,
					oid,
					eid,
					uid
				}
			});
		};
		history.push(`/${provider}`);
	};
	
	useEffect(() => {
		if(!logRocketInit.current && !!hsid) {
			logRocketInit.current = true;
			console.log('Setting LogRocket identity:', hsid);
			LogRocket.init('07kdby/approvalbuyontrust');
			LogRocket.identify(hsid);
		};
		// eslint-disable-next-line
	}, [logRocketInit.current, hsid]);

	useEffect(() => {
		if(provider === 'uown') {
			window.scrollTo(0, 0);
			openProviderModal();
			return;
		};
		// eslint-disable-next-line
	}, [provider]);

	
    const startApplication = (info) => {
		console.log(info?.addUserOptin?.message ?? 'Something broke!');
		window.scrollTo(0, 0);
		openProviderModal();
	};

	const logTheError = (error) => {
		console.error('Saving User: FAILED', error);
		LogRocket.captureMessage('Error occurred adding user opt-in!', {
			tags: {
				handlerType: 'mutation',
				handlerName: 'postUserOptin'
			},
			extra: { pageName: 'PreApplication', error },
		});
		window.scrollTo(0, 0);
		history.push('/bot');
	};
	
	const [addUserOptin] = useMutation(ADD_USER_OPTIN, { onCompleted: startApplication, onError: logTheError });

	const handleSubmit = (data) => {
		setLoading(true);
		GtagEvent('Approvals', 'Form Sent', 'Contact Info', 'Opening Application Modal');
		const payload = {			
			...data,
			clickId: Number(trackingState.hsid)
		};
		dispatchApp({ type: 'FORM_SUBMIT', payload });
		addUserOptin({ variables: { visitor: payload } } );
    };

	const handleBackClick = () => {
		setLoading(false);
		setShowDialog(false);
	};

	const handleConfirm = () => {
		history.push('/westcreek');
	};
    
    return (
		showDialog ? 
			<ConfirmDialog open={showDialog} close={handleBackClick} handleSubmission={handleConfirm}/> :
			<div style={styles.container}>
				<div style={styles.formBody}>								
					<h3 style={styles.formTitle}>Enter your information to begin</h3>
					<Form style={styles.formContent} handleFormSubmit={handleSubmit} loading={loading}/>
				</div>
			</div>
    )
});

export default UserInfo;