import React, { useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context';
import styles from './NoChecking.css.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const OfferButton = ({ sid, eid }) => {
    const [ isHovering, setHovering ] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');

    const handleLinkoutClick = () => {
        const linkout = `https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9490&pid=3372&eid=${sid}&uid=${eid}`;
        window.open(linkout, '_blank');
    };
    const ButtonRowStyle = Object.assign({},
        matches ? styles.offerButtonRow : styles.offerButtonRowSm
    );
    const ButtonStyle = Object.assign({},
        isHovering && styles.hover,
        matches ? styles.offerButton : styles.offerButtonSm
    );

    return (
        <div style={styles.offerButtonContainer}>
            <div style={styles.offerButtonHeader}>Continue Your Search for Lease to Own Electronics</div>
            <div style={styles.offerSubHeader}>Sorry we could not approve you at this time. The following options could help</div>
            <div style={ButtonRowStyle}>
                <button
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)} 
                    onClick={handleLinkoutClick} 
                    style={ButtonStyle}
                >
                    VIEW LEASE TO OWN OPTIONS
                </button>
                <div style={styles.offerButtonSponsored}>Sponsored Listings</div>
            </div>
        </div>
    )
};
const CheckingOfferButton = ({ sid, eid }) => {
    const [ isHovering, setHovering ] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');

    const handleLinkoutClick = () => {
        const linkout = `https://www.bkoffers.com/hitstreet/redirect_tp.cfm?oid=19&sid=9488&pid=3370&eid=${sid}&uid=${eid}`;
        window.open(linkout, '_blank');
    };
    const ButtonRowStyle = Object.assign({},
        matches ? styles.offerButtonRow : styles.offerButtonRowSm
    );
    const ButtonStyle = Object.assign({},
        isHovering && styles.hover,
        matches ? styles.offerButton : styles.offerButtonSm
    );

    return (
        <div style={styles.offerButtonContainer}>
            <div style={styles.offerButtonHeader}>Search for FREE Checking Account Options</div>
            <div style={styles.offerSubHeader}>Sorry we could not approve you at this time. The following options could help</div>
            <div style={ButtonRowStyle}>
                <button
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)} 
                    onClick={handleLinkoutClick} 
                    style={ButtonStyle}
                >
                    VIEW FREE CHECKING OPTIONS
                </button>
                <div style={styles.offerButtonSponsored}>Sponsored Listings</div>
            </div>
        </div>
    )
};

const NoChecking = () => {
    const { appState, trackingState } = useContext(AppContext);
    const { user } = appState;
    const { sid, eid } = trackingState;
    const name = user?.first ?? 'Valued Person'

    return (
        <div className='page-container'>
            <div className='decline__container'>
                <div className='decline__row'>
                    {`Dear ${name},`}
                </div>
                <div className='decline__row'>
                    Unfortunately, you must have an active checking account to take part in our program.<br/> Fortunately, many banks are offering FREE CHECKING ACCOUNTS and are even offering cash bonuses to sign up!
                </div>
                <OfferButton sid={sid} eid={eid}/>
                <CheckingOfferButton sid={sid} eid={eid} />
                <div className='decline__row'>
                    We would love you to join the BuyOnTrust.com family and welcome you to reapply at anytime in the future. Just be sure to wait at least 48 hours.
                </div>
                <div className='decline__row'>
                    Thank you from everyone at the BuyOnTrust.com family.
                </div>
            </div>
        </div>
    );
}

export default NoChecking;