const styles = {
    container: {
        minWidth: '100%',
        minHeight: '800px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    iframeStyle: {
        padding: '5vh 0',
        width: '100%',
        minWidth: '1000px',
        height: '100%',
        minHeight: '1000px',
        border: 'none',
        marginTop: '50px',
        overflow: 'hidden',
        '@media (max-width: 767px)': {
            padding: '40px 0 0 0',
            minHeight: '100vh',
            marginTop: '0',
            width: '100vw',
            minWidth: '100vw',
        }
    },
    hideIframe: {
        display: 'none'
    },
    loadingContainer: {
        width: '100%',
        minWidth: '1000px'
    },
    mobileLoadingContainer: {
        minHeight: '0',
        minWidth: '100vw',
        padding: '30vh 0 0 0'
    },
    dialog: {
        // width: '80vw'
    }
};
export default styles;