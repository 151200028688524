const numberList = {
    acima: [
        '011401533',
        '031101169',
        '031101279',
        '031902766',
        '041215663',
        '042200910',
        '042287027',
        '073972181',
        '084003997',
        '084106768',
        '091302966',
        '096017418',
        '103100195',
        '107000262',
        '113024588',
        '121000248',
        '122106455',
        '122187238',
        '122287675',
        '124071889',
        '124303120',
        '124303162',
        '124303201',
        '125108997',
        '263079014',
        '303085476',
        '303986261',
        '321076470',
        '321170839',
        '322281507'
    ],
    aff: [
        '011401533',
        '031101169',
        '031101279',
        '031902766',
        '041215663',
        '042200910',
        '042287027',
        '073972181',
        '084003997',
        '084106768',
        '091302966',
        '096017418',
        '103100195',
        '107000262',
        '113024588',
        '121000248',
        '122106455',
        '122187238',
        '122287675',
        '124071889',
        '124303120',
        '124303162',
        '124303201',
        '125108997',
        '263079014',
        '303085476',
        '303986261',
        '321076470',
        '321170839',
        '322281507'
    ],
    westcreek: [
        '011401533',
        '031101169',
        '031101279',
        '031902766',
        '041215663',
        '042200910',
        '042287027',
        '073972181',
        '084003997',
        '084106768',
        '091302966',
        '096017418',
        '103100195',
        '107000262',
        '113024588',
        '121000248',
        '122106455',
        '122187238',
        '122287675',
        '124071889',
        '124303120',
        '124303162',
        '124303201',
        '125108997',
        '263079014',
        '303085476',
        '303986261',
        '321076470',
        '321170839',
        '322281507'
    ],
    flex: [
        '011401533',
        '031101169',
        '031101279',
        '031902766',
        '041215663',
        '042200910',
        '042287027',
        '073972181',
        '084003997',
        '084106768',
        '091302966',
        '096017418',
        '103100195',
        '107000262',
        '113024588',
        '121000248',
        '122106455',
        '122187238',
        '122287675',
        '124071889',
        '124303120',
        '124303162',
        '124303201',
        '125108997',
        '263079014',
        '303085476',
        '303986261',
        '321076470',
        '321170839',
        '322281507'
    ]
};
export default numberList;