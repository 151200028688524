export default {
    inputContainer: {
        marginBottom: '20px',
        width: '100%'
    },
    mobileInputContainer: {
        // width: '70%'
    },
    formInput: {
        margin: '0',
        width: '100%',
        fontSize: '16px',
        lineHeight: '27px',
        color: '#495057',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    },
    error: {
        color: 'red',
        fontSize: '0.8rem',
        position: 'relative',
        width: '100%',
        top: '0',
        textAlign: 'right',
        fontWeight: 300
    }
}