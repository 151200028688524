import gql from 'graphql-tag.macro';

const ADD_NEW_USER = gql`
	mutation AddNewUser($visitor: NewUser) {
		addNewUser(visitor: $visitor) {
			success
			statusCode
			message
		}
	}
`;

const ADD_USER_OPTIN = gql`
	mutation AddUserOptin($visitor: AddOptin) {
		addUserOptin(visitor: $visitor) {
			success
			statusCode
			message
		}
	}
`;

const ADD_USER_APPROVAL = gql`
	mutation AddUserApproval($clickId: Int, $phone: String, $email: String, $approvalData: ApprovalData) {
		addUserApproval(clickId: $clickId, phone: $phone, email: $email, approvalData: $approvalData) {
			success
			statusCode
			message
		}
	}
`;

const ADD_SUBSCRIBER = gql`
	mutation AddNewSubscriber($subscriber: Subscriber) {
		addNewSubscriber(subscriber: $subscriber) {
			success
			statusCode
			message
		}
	}
`;

const POST_DORADO = gql`
	mutation PostUserDorado($user: DoradoUser) {
		postUserDorado(user: $user){
			success
			statusCode
			message
		}
	}
`;

const ADD_APP_START = gql`
	mutation addAppStart($provider: String, $pid: Int, $sid: Int, $oid: Int, $eid: String, $uid: String) {
		addAppStart(provider: $provider, pid: $pid, sid: $sid, oid: $oid, eid: $eid, uid: $uid){
			success
			statusCode
			message
		}
	}
`;

export {
	ADD_NEW_USER,
	ADD_USER_OPTIN,
	ADD_USER_APPROVAL,
	ADD_SUBSCRIBER,
	POST_DORADO,
	ADD_APP_START
};