import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const useSentry = (initProps) => {
    const { history, dsn, page, version } = initProps;
    useEffect(() => {
        (function () {
            Sentry.init({
                dsn,
                integrations: [new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                })],
                tracesSampleRate: 1.0,
                release: page + '@' + version,
                ignoreErrors: [
                    'ResizeObserver loop limit exceeded',
                    'AbortError: Failed to get ServiceWorkerRegistration objects: The Service Worker system has shutdown.',
                    'ResizeObserver loop completed with undelivered notifications.',
                    'cancelled'
                ],
            });
        })()

        return;
        // eslint-disable-next-line
    }, []);
};

export default useSentry;