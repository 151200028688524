import React, { forwardRef, useState, useContext } from 'react';
import { AppContext } from '../../../../../context';
import { useFormContext, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styles from './Inputs.css.js';
import valid from './errors';

const PhoneInputComponent = (props) => {
    const { name, onChange, inputRef, errors, ...restProps } = props

    return (
        <FormControl error={!!errors['phone']} style={styles.formInput}>
            <InputLabel htmlFor={name}>Phone Number</InputLabel>
            <Input
                name={name}
                id={name}
                inputProps={restProps}
                inputRef={inputRef}
                onChange={onChange}
                type='tel'
            />
        </FormControl>
    )
};

const CustomMaskedInput = forwardRef((props, ref) => {
    const { value, onChange, name, errors } = props;

    const beforeMaskedStateChange = ({ nextState }) => {
        let { value } = nextState;
        if(value === '+1(') return { ...nextState, value: '' };
        return { ...nextState, value };
    };

    return (
        <MaskedInput
            name={name}
            value={value}
            mask='+1(999)999-9999'
            maskPlaceholder={null}
            alwaysShowMask={false}
            inputRef={ref}
            errors={errors}
            beforeMaskedStateChange={beforeMaskedStateChange}
            onChange={e => {
                e.persist();
                onChange(e.target.value);
            }}
        >
            <PhoneInputComponent />
        </MaskedInput>
    );
});

const setPhoneForInput = (phone) => {
    if(phone.length === 10) {
        return `1${phone}`;
    };
    return phone;
};

const NestedInput = (props) => {
    const { appState } = useContext(AppContext);
    const { user } = appState;
    const [ tel, setTel ] = useState(!!user.phone ? setPhoneForInput(user.phone) : '');
    const { formState, errors, control, ...restProps } = props

    return (
        <>
        <Controller
            render={(props) => <CustomMaskedInput errors={errors} {...props}/>}
            value={tel}
            onChange={([e]) => {
                setTel(e);
                return { value: e };
            }}
            name='phone'
            control={control}
            defaultValue={tel}
            rules={valid.phoneErr}
            {...restProps}
        />
        {errors['phone'] && (<div style={styles.error}>{errors['phone'].message}</div>)}
        </>
    )
};

const PhoneInput = () => {
    const methods = useFormContext();

    return <NestedInput {...methods}/>;
};

export default PhoneInput;