import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SubmitButton.css.js';
import Radium from 'radium';
import { useMediaQuery } from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const SubmitButton = Radium(({ disabled, loading }) => {
    const [ isHovering, setHovering ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const themedStyle = Object.assign({},
        styles.submitButton,
        styles[disabled ? 'disabled' : 'active'],
        isHovering && !loading && !disabled && styles.hover,
        loading && styles.loadingButton
    );

    return (
        <button
            onMouseEnter={() => setHovering(!isMobile)}
            onMouseLeave={() => setHovering(false)}
            style={themedStyle}
            type='submit'
        >
            {loading ?
                <Fade in={loading} unmountOnExit>
                    <CircularProgress size={30}/>
                </Fade> :
                <>
                Continue
                <FontAwesomeIcon
                    icon={['fal', 'angle-double-right']}
                    style={styles.icon}
                />
                </>
            }          
        </button>
    )
});

export default SubmitButton;