import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import HeadShake from 'react-reveal/HeadShake';
import styles from './GetApproved.css';
import { useMediaQuery } from 'react-responsive';

const GetApproved = ({ isOffset = false }) => {
    let history = useHistory();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { dispatchApp, GtagEvent } = useContext(AppContext);

    const handleClick = () => {
        dispatchApp({ type: 'START_FLOW' });
        GtagEvent('Approvals', 'Click', 'Get Started', 'Open Opt-in Modal');
        history.push('/Approval');
        window.scrollTo(0, 0);
    };

    const buttonContainerStyle = Object.assign({},
        styles.buttonContainer,
        isOffset && styles.offsetButtonContainer,
        isMobile && styles.mobileButtonContainer
    );
    return (        
        <div style={buttonContainerStyle}>
            <HeadShake forever timeout={2000} >
                <button type='button' className='pre-button' onClick={handleClick}>
                    <span>
                        Click Here to
                        <b>{` Apply Now`}</b>
                    </span>
                </button>
            </HeadShake>
        </div>
    )
}

export default GetApproved;