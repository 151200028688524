import React from 'react';
import styles from './ApprovalCustom.css';
import Radium from 'radium';

const LegalDisclaimer = () => (
    <div style={styles.legal}>
        *Pre-qualification is based on data that you submit. Getting pre-qualified does not guarantee approval.
    </div>
)

export default Radium(LegalDisclaimer);