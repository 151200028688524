import {
    getCookie,
    checkCookie,
    // setCookies,
    setCookie
} from '../utilities/helpers';
import LogRocket from 'logrocket';

const initialTrackingState = {
    oid: null,
    pid: null,
    eid: null,
    sid: null,
    uid: null,
    hsid: null,
    city: 'N/A',
    state: 'N/A',
    country: 'N/A',
    zip: 'N/A',
    ip_address: checkCookie('ip') ? getCookie('ip') : 'N/A',
    kwd: checkCookie('kwd') ? getCookie('kwd') : null,
    se: checkCookie('se') ? getCookie('se') : null,
    pacid: null,
    pt1: checkCookie('pt1') ? getCookie('pt1') : null,
    pt2: checkCookie('pt2') ? getCookie('pt2') : null,
    gclid: checkCookie('gclid') ? getCookie('gclid') : '', // Missing value should be sent as empty string for redirect purposes
    fname: checkCookie('fname') ? getCookie('fname') : 'N/A',
    lname: checkCookie('lname') ? getCookie('lname') : 'N/A',
    email: checkCookie('email') ? getCookie('email') : 'N/A',
    phone: checkCookie('phone') ? getCookie('phone') : 'N/A',
    provider: null,
    count: checkCookie('count') ? getCookie('count') : null
};

const trackingStateReducer = (state, action) => {
    switch (action.type) {
        case 'USER_ARRIVED':
            let tracking = {
                oid: action.payload.oid || initialTrackingState.oid,
                pid: action.payload.pid || initialTrackingState.pid,
                eid: action.payload.eid || initialTrackingState.eid,
                sid: action.payload.sid || initialTrackingState.sid,
                uid: action.payload.uid || initialTrackingState.uid,
                hsid: action.payload.hsid || initialTrackingState.hsid,
                se: action.payload.se || initialTrackingState.se,
                kwd: action.payload.kwd || initialTrackingState.kwd,
                pacid: action.payload.pacid || initialTrackingState.pacid,
                pt1: action.payload.pt1 || initialTrackingState.pt1,
                pt2: action.payload.pt2 || initialTrackingState.pt2,
                gclid: action.payload.gclid || initialTrackingState.gclid,
                fname: action.payload.fname || initialTrackingState.fname,
                lname: action.payload.lname || initialTrackingState.lname,
                email: action.payload.email || initialTrackingState.email,
                phone: action.payload.phone || initialTrackingState.phone,
                count: !!initialTrackingState.count ? initialTrackingState.count : action.payload.count
            };
            if(!!tracking.gclid) {
                setCookie('gclid', tracking.gclid, 90);
            };
            if(!!tracking.count) {
                setCookie('count', tracking.count, 1);
            };
            // setCookies(tracking);
            return {
                ...state,
                ...tracking
            };

        case 'HSID_FOUND':
            // setCookie('hsid', action.payload, 3);
            LogRocket.identify(action.payload);
            return {
                ...state,
                hsid: action.payload
            };
            
        case 'LOCATION_FOUND':
            setCookie('city', action.payload.city, 3);
            setCookie('state', action.payload.state, 3);
            setCookie('country', action.payload.country, 3);
            setCookie('zip', action.payload.zip, 3);
            setCookie('ip', action.payload.ip_address, 3);
            return {
                ...state,
                city: action.payload.city || initialTrackingState.city,
                state: action.payload.state || initialTrackingState.state,
                country: action.payload.country || initialTrackingState.country,
                zip: action.payload.zip || initialTrackingState.zip,
                ip_address: action.payload.ip_address || initialTrackingState.ip_address,
            };

        case 'SET_PROVIDER':
            return {
                ...state,
                provider: action.payload
            }; 

        case 'RESET':
            return initialTrackingState;        

        default:
            throw new Error(`Not supported action ${action.type}`);
    }
};

export {
    initialTrackingState,
    trackingStateReducer
};