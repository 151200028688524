const styles = {
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '25px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        minWidth: '100vw',
        maxWidth: '100vw',
    },
    offsetButtonContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: '22vw',
        maxWidth: '100vw',
    },
    mobileButtonContainer: {
        padding: '10px'
    }
};
export default styles;