import React, { createContext, useReducer, useState } from 'react';
import ReactGA from 'react-ga';
import { initialAppState, appStateReducer } from '../reducers/appReducer';
import { initialTrackingState, trackingStateReducer } from '../reducers/trackingReducer';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    // Reducers
	const [appState, dispatchApp] = useReducer(appStateReducer, initialAppState);
    const [trackingState, dispatchTracking] = useReducer(trackingStateReducer, initialTrackingState);
    const [initialLoad, setInitialLoad] = useState(true);

    const GtagValueEvent = (category, action, label, value, page) => {
        console.log('GTag event fired:', page);
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(page);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: Number(value)
        });
    };

    const GtagEvent = (category, action, label, page) => {
        console.log('GTag event fired:', page);
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(page);
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    };

	const defaultContext = {
        appState, 
        dispatchApp,
        trackingState,
        dispatchTracking,
        GtagEvent,
        GtagValueEvent,
        initialLoad,
        setInitialLoad,
	};
	
	return (
        <AppContext.Provider value={defaultContext}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };