export default {
    data: {
        legal: `By providing my mobile number and clicking “Continue”, 
        I consent to receive marketing text messages from or on behalf 
        of BuyOnTrust that may be sent by an automatic telephone dialing 
        system. I understand that providing consent is not a condition of 
        purchasing any good or service from BuyOnTrust. Message and data 
        rates may apply.`
    },
    formContent: {
        padding: '20px 5vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            padding: '10px 0',
        }
    },
    legalText: {
        position: 'relative',
        display: 'block',
        fontSize: '.75rem',
        color: 'rgba(57, 59, 60, 0.35)',
        padding: '10px 20px',
        marginBottom: '.75rem',
        '@media (max-width: 767px)': {
            padding: '10px 0'
        }
    }
}