import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import ReactGA from 'react-ga';
import { AppContextProvider } from '../context';
import { ApolloProvider } from '@apollo/client';
import useSentry from '../hooks/useSentry';
import Radium, { StyleRoot } from 'radium';
import App from './App';
import useApolloClient from '../hooks/useApolloClient';
// import LogRocket from 'logrocket';
// import * as Sentry from '@sentry/react';

// fontawesome
import initFontAwesome from '../utilities/initFontAwesome';
initFontAwesome();

const history = createBrowserHistory();

const Global = () => {
	useSentry({ 
		history,
		dsn: 'https://52fc46bba5d340f08db58f8c76023eeb@o440028.ingest.sentry.io/5455790',
		page: 'bot-approval',
		version: '1.0.0'
	});
// ** currently prod: Active **
// ** currently staging: Active **
	// const apolloUri = 'https://mfzgtl2zoj.execute-api.us-east-1.amazonaws.com/prod/graphql';
	const apolloUri = 'https://3um9v9wuv7.execute-api.us-east-1.amazonaws.com/active/graphql';
	// const apolloUri = 'https://7cv2781k2a.execute-api.us-east-1.amazonaws.com/staging/graphql';
	// const apolloUri = 'https://k85tojwt8i.execute-api.us-east-1.amazonaws.com/test/graphql';
	// const apolloUri = 'http://localhost:4000/dev/graphql';

	// const westcreekHeaders = { 'public-dealer-id': process.env.REACT_APP_WC_PUBLIC_ID_STAGE };
	const westcreekHeaders = { 'public-dealer-id': process.env.REACT_APP_WC_PUBLIC_ID };
	
	const myClient = useApolloClient(apolloUri, westcreekHeaders);

	const WrappedApp = Radium(App);

	return (
		<ApolloProvider client={myClient}>
			<AppContextProvider>
				<Router history={history}>
					<StyleRoot>
						<WrappedApp />
					</StyleRoot>
				</Router>
			</AppContextProvider>
		</ApolloProvider>
	);
};

// export default Sentry.withProfiler(Global, { name: 'BuyOnTrust - Approval', includeUpdates: false });
export default Global;