import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Question Pages
import Landing from '../components/Pages/Landing';
import Congratulations from '../components/Pages/Result/Congratulations';
import ApprovalAcima from '../components/Pages/ApprovalAcima';
import ApprovalWestCreek from '../components/Pages/ApprovalWestCreek';
import ApprovalAFF from '../components/Pages/ApprovalAFF';
import ApprovalFlex from '../components/Pages/ApprovalFlex';
import ApprovalPayTomorrow from '../components/Pages/ApprovalPayTomorrow';
import ApprovalTerrace from '../components/Pages/ApprovalTerrace';
import ApprovalCustom from '../components/Pages/ApprovalCustom';
import ApprovalUown from '../components/Pages/ApprovalUown';
import CheckMark from '../components/Pages/CheckMark';
import RoutingNumber from '../components/Pages/RoutingNumber';
import UserInfo from '../components/Pages/UserInfo';
import Checking from '../components/Pages/CheckingAccount';
import NoChecking from '../components/Pages/NoChecking';
import BadChecking from '../components/Pages/BadChecking';
import Decline from '../components/Pages/Result/Decline';
import DeclineAff from '../components/Pages/Result/DeclineAff';
import DeclineFlex from '../components/Pages/Result/DeclineFlex';
import RouteWrapper from './RouteWrapper';
import Radium from 'radium';

const Routes = () => (
    <RouteWrapper>
        <Switch>
            <Route path='/' exact component={Landing} />
            <Route path='/Approval' component={UserInfo} />
            <Route path='/Routing' component={RoutingNumber} />
            <Route path='/Checking' component={Checking} />
            <Route path='/NoChecking' component={NoChecking} />
            <Route path='/NotCompatible' component={BadChecking} />
            <Route path='/Check' component={CheckMark} />
            <Route path='/acima' component={ApprovalAcima} />
            <Route path='/westcreek' component={ApprovalWestCreek} />
            <Route path='/aff' component={ApprovalAFF} />
            <Route path='/flex' component={ApprovalFlex} />
            <Route path='/paytomorrow' component={ApprovalPayTomorrow} />
            <Route path='/terrace' component={ApprovalTerrace} />
            <Route path='/bot' component={ApprovalCustom} />
            <Route path='/uown' component={ApprovalUown} />
            <Route path='/Congratulations' component={Congratulations} />
            <Route path='/Declination' component={Decline} />
            <Route path='/DeclinationAFF' component={DeclineAff} />
            <Route path='/DeclinationFlex' component={DeclineFlex} />
            <Route path='/Abandoned' component={Landing} />
        </Switch>
    </RouteWrapper>
);

export default Radium(Routes);