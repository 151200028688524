import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDoubleRight, faAngleDoubleLeft,
  faLightbulbOn, faSearchDollar, 
} from '@fortawesome/pro-light-svg-icons';

function initFontAwesome() {
  library.add(
  faLightbulbOn,
  faSearchDollar,
  faAngleDoubleRight,
  faAngleDoubleLeft
  );
}

export default initFontAwesome;
