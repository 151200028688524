const styles = {
    submitButton: {
        fontWeight: '400',
        padding: '10px',
        fontSize: '1.25rem',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: '60%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '50px',
        textTransform: 'uppercase',
    },
    active: {
        color: '#fff',
        backgroundColor: 'rgb(35, 160, 221)',
    },
    disabled: {
        color: 'rgba(0,0,0,0.3)',
        backgroundColor: 'rgba(0,0,0,0.1)',
        border: 'none'
    },
    hover: {
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.4rem',
    },
    icon: {
        marginLeft: '20px',
        fontSize: '1.75rem'
    },
    loadingButton: {
        opacity: '0.5'
    }
};

export default styles;