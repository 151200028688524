import React from 'react';
import TextField from '@material-ui/core/TextField';
import styles from './Inputs.css.js';
import { useMediaQuery } from 'react-responsive';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const CurrencyMaskInput = (props) => {
    const { inputRef, ...other } = props;
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...other,
    })

    return <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={currencyMask}
            />
};
  
CurrencyMaskInput.defaultProps = {
    inputMode: 'numeric',
    other: {},
};

const CurrencyInput = ({ name, text, error, errorMessage, value, setValue }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const inputStyle = Object.assign({}, 
        styles['inputContainer'],
        isMobile && styles['mobileInputContainer']
    );

    return (
        <div style={inputStyle}>
            <TextField
                id={`${name}-text-field`}
                style={styles.formInput} 
                name={name}
                label={text}
                value={value}
                onChange={setValue}
                InputProps={{
                    'aria-label': `${name}-input-field`,
                    inputComponent: CurrencyMaskInput
                }}
                error={error}
            />
            {error && (<div style={styles.error}>{errorMessage}</div>)}
        </div>
    )
};

export default CurrencyInput;